import React from 'react'
import {
    Route,
    Redirect,
} from 'react-router-dom'


const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        localStorage.getItem('user_data') && JSON.parse(localStorage.getItem('user_data')).token ?
            (JSON.parse(localStorage.getItem('user_data')).is_verified)?
                <Component {...props} />
            :
                <Redirect to={{
                    pathname: '/empty',
                    state: { from: props.location }
                }} />
            : <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }} />
    )} />
)

export default PrivateRoute;
