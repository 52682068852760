import { trackPromise } from 'react-promise-tracker';
import env from '../../env/envSelection'

const USER_LOGIN = "USER_LOGIN";
const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export default function userLogin(emailid, password) {
    return (dispatch) => {
        var path = '/user/login' + '?timestamp = ' + new Date().getTime();
        dispatch(apiCallStart());
        trackPromise(
            fetch(env.audifyzdomain+path, {
                method: 'POST',
                headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'x-api-key': 'c3VyZmF0cmlwLWtleQ==',
                },
                body: JSON.stringify({
                    "email": emailid,
                    "password_hash": password
                }),
            })
                .then((response) => {
                    // if (response.headers.get('content-type').match(/application\/json/)) {
                        return response.json();
                    // }
                    // return response;
                })
                .then((jsonresponse) => {
                    dispatch(apiCallSuccess(jsonresponse))
                })
                .catch((error) => dispatch(apiCallFail(error)))
        )
    }
}



function apiCallStart() {
    return {
        type: USER_LOGIN
    }
}

function apiCallSuccess(data) {
    return {
        type: USER_LOGIN_SUCCESS,
        data: data
    }
}

function apiCallFail(error) {
    return {
        type: USER_LOGIN_FAIL,
        error
    }
}
