import React from 'react';
import './scheduleDemo.css';
import '../Home/Home.css';
import Footer from '../Footer/footer';
import Chat from '../ChatLibrary/chat.js';
import Loader from 'react-loader-spinner';
import env from '../../env/envSelection';
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = {}; 
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init('1052253578677047', advancedMatching, options);
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');

class ScheduleForDemo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            loading: false,
        }
    }
    componentDidMount() {
        this.props.setScheduleTabActive(true);
    }
    componentWillUnmount() {
        this.props.setScheduleTabActive(false);
    }
    submitDetails() {
        this.setState({ loading: true })
        var path = '/callschedule/add' + '?timestamp = ' + new Date().getTime();
        fetch(env.audifyzdomain + path, {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-api-key': 'c3VyZmF0cmlwLWtleQ==',
          },
          body: JSON.stringify({
            "username": this.state.name,
            "email": this.state.email,
          }),
        })
        .then((response) => {
            console.log('response: ',response);
            return response.json();
        })
        .then(response => {
            this.setState({ loading: false, callModal: false });
        })
        .catch((err) => {
            this.setState({ loading: false })
            console.log(err)
        })
    }
    render() {
        return (
            <div>
                <Chat/>
                <div className="topDiv">
                </div>
                <div className="popclose_innerdiv">
                    <div className="logoheading_horizontal">
                    <img className="popup_logo" src={require('../../images/logo_audifyz_copy.png')} alt='logo' />
                    <div className="popup_heading">AUDiFYZ</div>
                    </div>
                    <div className="popup_title popup_horiz_separator">Any questions? Schedule a live demo or a call with us.</div>
                    <div className="popup_title2">Give us your details and we are happy to help you get to know our platform better.</div>
                    <input className="popup_textinput popup_horiz_separator" placeholder="Enter name here." value={this.state.name}
                    onChange={evt => this.setState({ name: evt.target.value })} type="text" />
                    <input className="popup_textinput" placeholder="Enter email address here." value={this.state.email}
                    onChange={evt => this.setState({ email: evt.target.value })} type="text" />
                    <div onClick={() => this.submitDetails()}
                        className={(this.state.loading || this.state.email.length < 6) ? "popup_submit popup_horiz_separator disabled_button" : "popup_submit popup_horiz_separator hvr-sweep-to-top"}>
                        {
                            this.state.loading ?
                            <Loader type="ThreeDots" color="#59cbb7"
                                width={60} height={60} />
                            :
                            'Submit'
                        }
                    </div>
                </div>
                <div className="bottomDiv">
                </div>
                <Footer/>
            </div >
        )
    }

}

export default ScheduleForDemo;