import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import Toolbar from '../OnpageToolbar/OnpageToolbar';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import SideDrawer from '../SideDrawer/SideDrawer';
import Backdrop from '../Backdrop/Backdrop';
import { navigateToUrl } from '../../utils/Navigating';
import Footer from '../Footer/footer';
import Chat from '../ChatLibrary/chat.js';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { trackPromise } from 'react-promise-tracker';
import TagManager from 'react-gtm-module';

import env from '../../env/envSelection';
import ReactPixel from 'react-facebook-pixel';
import Exitfeedback from '../Exitfeedback/exitfeedback';
const advancedMatching = {};
const options = {
    autoConfig: true,
    debug: false,
};
ReactPixel.init('1052253578677047', advancedMatching, options);
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');

var sampleAudio;

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scroll_value: this.props.location.scroll_id,
            exitFeedbackTrigger:false,
            show_video: false,
            isLoggedIn: false,
            userData: '',
            audioPlaying: 100,
            isPlaying: false,
            windowWidth: 0,
            windowHeight: 0,
            showGuideBook: false,
            guidebookName: '',
            guidebookEmail: '',
            loading: false,
        }
        this.snowContainer = null;
        this.snowContent = ['&#10052;', '&#10053;', '&#10054;'];
    }

    

    openSocialMedia(url=''){
        window.open(url,"_blank")
    }
    random = (num) => {
        return Math.floor(Math.random() * num);
    }

    getRandomStyles = () => {
        const top = this.random(100);
        const left = this.random(100);
        const dur = this.random(10) + 10;
        const size = this.random(25) + 30;
        return ` 
            top: -${top}%; 
            left: ${left}%; 
            font-size: ${size}px; 
            animation-duration: ${dur}s; 
        `;
    }

    createSnow = (num) => {
        if (this.snowContainer) {
            for (let i = num; i > 0; i--) {
            const snow = document.createElement("div");
            snow.className = "snow";
            snow.style.cssText = this.getRandomStyles();
            snow.innerHTML = this.snowContent[this.random(3)]; // Adjust to the correct length
            this.snowContainer.appendChild(snow);
            }
        }
    }

    componentDidMount() {
        this.createSnow(50);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions.bind(this));
        this.state.scroll_value != null && this.scrollToMyRef(this.state.scroll_value);
        if (localStorage.getItem('user_data')) {
            this.setState((state, props) => {
                return {
                    isLoggedIn: true,
                    userData: JSON.parse(localStorage.getItem('user_data')),
                }
            })
        }
        this.getJsonFile();
        this.getHomeJson();
    }

    getHomeJson() {
        fetch('https://' + env.s3BucketConfig.bucketName + '.s3-us-west-2.amazonaws.com/public/website/home/home_data.json?timestamp = ' + new Date().getTime(), {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                // console.log('pricingData',data)
                var benefits = data.pricing_details.find((plan) => plan.id == 3).description.split("|");
                this.setState({
                    benefits: benefits,
                    pricingData: data.pricing_details
                })
            })
            .catch((err) => { console.log(err) })
    }

    getJsonFile() {
        trackPromise(
            fetch('https://' + env.s3BucketConfig.bucketName + '.s3-us-west-2.amazonaws.com/public/website/home/sampleaudios/sampleAudio1.json', {
                method: 'GET',
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('JSONdata',data)
                    this.setState({ sampleData: data })
                })
                .catch((err) => { console.log(err) })
            , 'modal_spinner_new')
    }

    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
        if (sampleAudio && sampleAudio != null) {
            sampleAudio.pause();
            sampleAudio = null;
        }
    }


    scrollToMyRef(id, position = "center") {
        var elmnt = document.getElementById(id);
        elmnt.scrollIntoView({ behavior: "smooth", block: position, inline: 'nearest' });
    }

    showVideo() {
        this.setState({
            show_video: true
        })
    }
    playPauseSample(count) {
        if (this.state.isPlaying && count == this.state.audioPlaying) {
            this.stopPlaying();
        } else {
            if (this.state.isPlaying) {
                sampleAudio.pause();
            }
            // sampleAudio = new Audio('https://d278admdl7nfkq.cloudfront.net/sample-books/sample_' + count + '.mp3');
            sampleAudio = new Audio('https://zdlab-audifyz-prod.s3.us-west-2.amazonaws.com/public/website/home/sampleaudios/sample_' + count + '.mp3');
            
            sampleAudio.play();
            this.setState({ isPlaying: true, audioPlaying: count })
            sampleAudio.addEventListener('ended', () => this.stopPlaying());
        }
    }

    stopPlaying() {
        sampleAudio.pause();
        this.setState({ isPlaying: false, audioPlaying: 100 })
    }

    drawerToggleClickHandler = () => {
        this.setState((prevstate) => {
            return {
                sideDrawerOpen: !prevstate.sideDrawerOpen
            };
        });
    };

    backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
    };

    navigation(url, id) {
        var urlObj = {
            pathname: url,
            sroll_id: id
        }
        navigateToUrl(this.props, urlObj);
    }

    navigationWithOutProps(url) {
        var urlObj = {
            pathname: url,
        };
        navigateToUrl(this.props, urlObj);
    }

    setExitFeedbackModalEnable = () => {
        // this.setState()
        this.setState((prevstate) => {
          return {
            exitFeedbackTrigger:true
          };
        });
    
      };
    
      setExitFeedbackModalDisable = () => {
        // this.setState()
        this.setState((prevstate) => {
          return {
            exitFeedbackTrigger:false
          };
        });
    
      };

    renderSamplePopUp(index) {
        return (
            <Popover style={{ width: '100%' }} id="popover-basic">
                <Popover.Title as="h3" style={{ marginTop: '0px' }}>
                    <div className="toolbar_userlist_title">
                        <div>{this.state.sampleData && this.state.sampleData[index].title}</div>
                        {/* <div style={{fontSize:'65%',paddingTop:'5px',color:'greypr'}}>{this.state.userData && !this.state.userData.is_verified && ' ,unverified'}</div> */}
                    </div>
                </Popover.Title>
                <Popover.Content>
                    {/*<div className="toolbar_popover_listitem_separator"></div>*/}
                    {this.state.sampleData && <div className="toolbar_popover_listitem">
                        <div><div style={{ color: 'grey', fontStyle: 'italic' }}>Written by: </div>{this.state.sampleData[index].author}</div>
                    </div>}
                    {this.state.sampleData && <div className="toolbar_popover_listitem">
                        <div><div style={{ color: 'grey', fontStyle: 'italic' }}>Narrated by: </div>{this.state.sampleData[index].narrator}</div>
                    </div>}
                    {this.state.sampleData && <div className="toolbar_popover_listitem">
                        <div><div style={{ color: 'grey', fontStyle: 'italic' }}>Duration: </div>{this.state.sampleData[index].duration}</div>
                    </div>}
                </Popover.Content>
            </Popover>
        );
    }

    renderCurrentInfo(text) {
        return (
            <Popover style={{ width: '100%' }} id="popover-basic">
                <div className="projectpagePopOver">
                    <div className="projectinfopop_innerdiv">
                        <div className="projectinfopop_desc">{text}</div>
                    </div>
                </div>
            </Popover>
        );
    }

    renderPlans(item, index) {
        // if (item.id == 2) {
        //     var cardBG = '#4EB9C5';
        //     var light_cardBG = '#C0C0C0'
        // } else if (item.id == 3) {
        //     var cardBG = '#3F67E0';
        //     var light_cardBG = '#CFB53B'
        // } else {
        //     var cardBG = '#5ECA51';
        //     var light_cardBG = '#cd7f32'
        // }
        if (item.is_enable == 1) {
            var opacity = 1;
            var pointerEve = 'auto';
        } else {
            var opacity = 0.3;
            var pointerEve = 'none';
        }
        var pageCount = (index == 0) ? 100 : (index == 1) ? 500 : 700;
        var benefitArr = item.description.split("|");
        return (
            <div key={index + 'Home_payment_plan'} className="homepageBasicPriceCardStyle">
                {/* <Exitfeedback exitFeedbackTrigger={this.state.exitFeedbackTrigger} onScheduleEvent = {this.setExitFeedbackModalDisable}/> */}
                <li className="homepagePriceingTypesHeading">{item.payment_plan}</li>
                <div className="homepage_plancard_pricediv">
                    <div className="homepagepriceDoller">
                        {/* <div className="homepagepriceDollerSign">&#36;</div> */}
                        <div className="homepagePrice">{(item.offer_enabled == 1) ? item.offer_price : item.price}</div>
                        {item.offer_enabled == 1 && <div className="homepage_slicedprice">{item.price}</div>}
                    </div>
                    <div className="homepagePriceperbook">per book</div>
                </div>
                <li className="homepagePriceingFeatures">All features including - </li>
                {this.state.benefits && this.state.benefits.map((benefit, bIndex) => {
                    return (
                        <div key={item.payment_plan + benefit} className="payment_plancard_listItemDiv">
                            <div className="payment_plancard_listItemDiv_header">
                                {benefitArr.includes(benefit) ?
                                    <img style={{ marginRight: '15px' }} className="payment_card_tick" src={require('../../images/correct.png')} alt="correct" />
                                    :
                                    <img style={{ marginRight: '15px' }} className="payment_card_tick" src={require('../../images/not.png')} alt="not" />
                                }
                                <div className="homepagePriceingFeaturesDis">{benefit.split("=")[0].trim()}</div>
                            </div>
                            <OverlayTrigger
                                delay={{ show: 250, hide: 150 }}
                                key={'refersh'}
                                placement={'top'}
                                overlay={this.renderCurrentInfo(benefit.split("=")[1].trim())}>
                                <img className="payment_card_tick" src={require('../../images/info (2).png')} alt="info" />
                            </OverlayTrigger>
                        </div>
                    );
                })}
                <li style={{ marginBottom: '-15px' }} className="homepagePriceingFeatures">{'*Number of pages – up to ' + pageCount + '.'}</li>
                <div className="homepage_portReadmoremainDiv" style={{ marginBottom: '20px' }}>
                    <li onClick={() => { 
                            const event = {
                                event: 'build_now_button_click',
                                button_label: 'build now'
                              };
                            TagManager.dataLayer({ dataLayer: event });
                            this.navigationWithOutProps('/projects') }} className="homepage_portBrowse hvr-sweep-to-top">
                        Build now
                    </li>
                </div>
            </div>
        );
    }

    renderGuideFeedback() {
        return (
            <Modal
                size="lg"
                animation={true}
                centered
                backdrop={'static'}
                className="exitfeedback_modal"
                show={this.state.showGuideBook} onHide={() => this.toggleGuideBookModal()}>
                <div className="exitfeedback_container">
                    <OverlayTrigger
                        delay={{ show: 180, hide: 300 }}
                        key={'close'}
                        placement={'right'}
                        overlay={
                            <Tooltip id={`closeinactiveModal`}>
                                Close
                            </Tooltip>
                        }>
                        <img onClick={() => this.toggleGuideBookModal()} className="popclose_closeImage" src={require('../../images/close.png')} alt="close" />
                    </OverlayTrigger>
                    <div className="popclose_innerdiv">
                        <div className="logoheading_horizontal">
                            <img className="popup_logo" src={require('../../images/logo_audifyz_copy.png')} alt='logo' />
                            <div className="popup_heading">AUDiFYZ</div>
                        </div>
                        <div className="popup_title popup_horiz_separator">Download how to make Audiobook - eBook.</div>
                        <div className="popup_title2">Give us your details and we are happy to send you a quick quidebook to know our platform better.</div>
                        <input className="popup_textinput popup_horiz_separator" placeholder="Enter name here." value={this.state.guidebookName}
                            onChange={evt => this.setState({ guidebookName: evt.target.value })} type="text" />
                        <input className="popup_textinput" placeholder="Enter email address here." value={this.state.guidebookEmail}
                            onChange={evt => this.setState({ guidebookEmail: evt.target.value })} type="text" />
                        <div onClick={() => {
                            const event = {
                                event: 'guide_book_submit_button_click',
                                button_label: 'guide_book_submit'
                              };
                            TagManager.dataLayer({ dataLayer: event });
                            this.submitGuideBookDetails()}}
                            className={(this.state.loading || this.state.guidebookEmail.length < 6) ? "popup_submit popup_horiz_separator disabled_button" : "popup_submit popup_horiz_separator hvr-sweep-to-top"}>
                            {
                                this.state.loading ?
                                    <Loader type="ThreeDots" color="#59cbb7"
                                        width={60} height={60} />
                                    :
                                    'Submit'
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    submitGuideBookDetails() {
        this.setState({ loading: true })
        var path = '/callschedule/add' + '?timestamp = ' + new Date().getTime();
        fetch(env.audifyzdomain + path, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'x-api-key': 'c3VyZmF0cmlwLWtleQ==',
            },
            body: JSON.stringify({
                "username": this.state.guidebookName,
                "email": this.state.guidebookEmail,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then(response => {
                this.setState({ loading: false, showGuideBook: false })
            })
            .catch((err) => {
                this.setState({ loading: false })
                console.log(err)
            })
    }


    toggleGuideBookModal() {
        this.setState((state, props) => {
            return {
                showGuideBook: !state.showGuideBook
            };
        });
    }

    render() {
        return (
            <div className="MainContainer">
                {this.renderGuideFeedback()}
                <Chat />
                {/* <div ref={(el) => this.snowContainer = el} id="snow-container" ></div> */}
                <Toolbar
                    onToolbarItemPress={(div_id) => this.scrollToMyRef(div_id)}
                    drawerClickHandler={this.drawerToggleClickHandler}
                    onScheduleEvent = {this.setExitFeedbackModalEnable}
                />
                <div className="Swiper_one">
                    <SideDrawer show={this.state.sideDrawerOpen} click={this.backdropClickHandler} />
                    {this.state.sideDrawerOpen && <Backdrop click={this.backdropClickHandler} />}
                    <div className="HomePageBannerMain" id='top'>
                        <div className="HomePageBannerVideoAndText">
                            <div className="HomePageBannerTextArea">
                                <div className="Main_text_Style">
                                    Build Your Own Audiobook
                                </div>
                                <li className="text_normalThree">
                                    At any place, with convenience and within your budget.
                                </li>
                                <div className="homePageDiscoverDiv">
                                    <div style={{ marginRight: '35px' }} className="hvr-sweep-to-top swiperScreenReadmore"
                                        onClick={() => {
                                            const event = {
                                                event: 'explore_button_click',
                                                button_label: 'explore'
                                              };
                                            TagManager.dataLayer({ dataLayer: event });
                                            this.scrollToMyRef('content', 'end')
                                            }}>
                                        Explore
                                    </div>
                                    <div className="hvr-sweep-to-top swiperScreenReadmore"
                                        onClick={() => {
                                            const event = {
                                                event: 'get_started_button_click',
                                                button_label: 'get started'
                                              };
                                            TagManager.dataLayer({ dataLayer: event });
                                            this.props.history.push("/projects");
                                        }}>
                                        Get started
                                    </div>
                                </div>
                            </div>
                            <div className="HomePageBannerVideoArea">
                                {/* {
                                    this.state.show_video === false ?
                                        <div className="Swiper_oneVideo"
                                            onClick={() => this.showVideo()}>
                                            <img className="youtube_icon" src={require('../../images/youtube_red.png')} type="video/mp4" />
                                        </div>
                                        :
                                        <div>
                                            <video className="Swiper_oneVideoWithoutBG" autoPlay muted controls
                                                onClick={() => this.showVideo()}>
                                                <source src={'https://d278admdl7nfkq.cloudfront.net/Images/Audifyz.mp4'} type="video/mp4" />
                                            </video>
                                        </div>
                                } */}
                                <iframe className="Swiper_oneVideo_youtube" src="https://www.youtube.com/embed/QdJBtXllM7Y" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                            </div>
                        </div>
                    </div>
                    <img className="homepage_offerbadge" src={require('../../images/offer_badge.png')} alt="setup" />
                    <div onClick={()=>{this.toggleGuideBookModal()}} className="vertical_semicircle">
                        <img className="verifybook_download" style={{ marginBottom: '10px' }} src={require('../../images/file-download.png')} alt="audiobook-download" />
                    </div>
                </div>
                <div className="HomePageBannerVideoAndText_Mobile">
                    <div className="Main_text_Style_Mobile">
                        Build Your Own Audiobook
                    </div>
                    <li className="text_normalThree_Mobile">
                        At any place, with convenience and within your budget.
                    </li>
                    <div className="homePageDiscoverDiv">
                        <div style={{ marginRight: '35px' }} className="hvr-sweep-to-top swiperScreenReadmore"
                            onClick={() => this.scrollToMyRef('content-setup', 'center')}>
                            <div>Explore</div>
                        </div>
                        <div className="hvr-sweep-to-top swiperScreenReadmore"
                            onClick={() => {
                                const event = {
                                    event: 'get_started_button_click',
                                    button_label: 'get started'
                                  };
                                TagManager.dataLayer({ dataLayer: event });
                                this.props.history.push("/projects")
                            }}>
                            Get started
                        </div>
                    </div>
                </div>
                <div className="OnapageToolbar_Video_MobileView">
                    {
                        // this.state.show_video === false ?
                        //     <div className="Swiper_oneVideo_Mobile"
                        //         onClick={() => this.showVideo()}>
                        //         <img className="youtube_icon_mobile" src={require('../../images/youtube_red.png')} type="video/mp4" />
                        //     </div>
                        //     :
                        //     <div>
                        //         <video className="Swiper_oneVideo_MobileWithoutBG" autoPlay muted controls
                        //             onClick={() => this.showVideo()}>
                        //             <source src={'https://d278admdl7nfkq.cloudfront.net/Images/Audifyz.mp4'} type="video/mp4" />
                        //         </video>
                        //     </div>
                        <iframe className="Swiper_oneVideo_Mobile_youtube" src="https://www.youtube.com/embed/QdJBtXllM7Y" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={false}></iframe>
                    }
                </div>
                <div className="Listen" style={{ paddingTop: '30px' }}>
                    <li className="Listen_text">Know more about Audifyz</li>
                    <li className="listen_caption">Three key steps to master the audiobook world.</li>
                </div>
                <div className="homepage_portfoli" id="content">
                    <div id={'content-setup'} className="homepage_portCard">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img className="homepage_portCardimageOne" src={require('../../images/gear.png')} alt="gear" />
                        </div>
                        <div className="homepage_portCardImageDivRow">
                            <div className="homepage_portCardInnerDiv">Setup</div>
                            <li className="homepage_portCardInnerDisc"> A quick guide to help you set up a basic infrastructure to start recording your first audiobook.
                            </li>
                            <div onClick={() => this.navigation('/how-to-make-an-audiobook-create-your-own', 'set')} className="homepage_portReadmoremainDiv">
                                <li className="hvr-sweep-to-top homepage_portBrowse">
                                    Read more
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className="homepage_portCard">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img className="homepage_portCardimageOne" src={require('../../images/music.png')} alt="music" />
                        </div>
                        <div className="homepage_portCardImageDivRow">
                            <div className="homepage_portCardInnerDiv">Record</div>
                            <li className="homepage_portCardInnerDisc">Procedures to record your book concerning its chapters and paragraphs into audio files.</li>
                            <div onClick={() => this.navigation('/how-to-make-an-audiobook-create-your-own', 'rec')} className="homepage_portReadmoremainDiv">
                                <li className="hvr-sweep-to-top homepage_portBrowse">
                                    Read more
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className="homepage_portCard">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img className="homepage_portCardimageOne" src={require('../../images/transportation.png')} alt="transportation" />
                        </div>
                        <div className="homepage_portCardImageDivRow">
                            <div className="homepage_portCardInnerDiv">Publish</div>
                            <li className="homepage_portCardInnerDisc">Effortless transitioning into publishing platforms due to congruent standards and aid.</li>
                            <div onClick={() => {
                                this.navigation('/how-to-make-an-audiobook-create-your-own', 'pub')}} className="homepage_portReadmoremainDiv">
                                <li className="hvr-sweep-to-top homepage_portBrowse">
                                    Read more
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Main_listen">
                    <div className="Listen" style={{ marginTop: '20px' }}>
                        <li className="Listen_text">Listen to our sample audios</li>
                        <li className="listen_caption">Successful publications. </li>
                    </div>
                    <div className="buildCards_main">
                        <div className="sampleAudios">
                            <OverlayTrigger trigger={['hover', 'hover']} placement={(this.state.windowWidth < 700) ? "bottom" : "right"} overlay={this.renderSamplePopUp(0)}>
                                <div className="squarecard" onClick={() => {
                                    const event = {
                                        event: 'play_pause_button_click',
                                        button_label: 'play_pause'
                                      };
                                    TagManager.dataLayer({ dataLayer: event });
                                    this.playPauseSample(0)}}>
                                    <img className={(this.state.audioPlaying == 0) ? "sampleCoverImage_playing" : "sampleCoverImage"} src={'https://d278admdl7nfkq.cloudfront.net/sample-books/cover_0.jpg'} alt="cover_0" />
                                    <div className="squarecards">
                                    <img onClick={()=>{this.openSocialMedia('https://www.audible.com/pd/The-Art-of-Transformation-Audiobook/B08NF7Q5LP?ref=web_search_eac_asin_2&qid=gRW70roN2p&sr=1-2')}} className={(this.state.audioPlaying == 4) ? "sampleplaypauses" : "sampleplaypauses"} src={require('../../images/Audible_logo.png')}  alt="audible" /></div>
                                    <div className={(this.state.audioPlaying == 0) ? "samplemiddle_playing1" : "samplemiddle"}>
                                        <img className="sampleplaypause" src={require((this.state.audioPlaying == 0) ? '../../images/stop-blue.png' : '../../images/play-blue.png')} alt="play-blue0" />
                                    </div>
                                </div>
                            </OverlayTrigger>
                            <div className="sampleauthordiv">
                                <div className="bookSoundFirstDivBookauthor"> </div>
                                {/* {this.state.audioPlaying == 0 && <div className="bookSoundFirstDivBookauthor_end">   <img onClick={()=>{this.openSocialMedia('https://www.audible.com/pd/The-Art-of-Transformation-Audiobook/B08NF7Q5LP?ref=web_search_eac_asin_2&qid=gRW70roN2p&sr=1-2')}} className="sampleplaypause" src={require('../../images/Audible_logo.png')} alt="audible" /></div>} */}
                                {this.state.audioPlaying == 0 && <div className="bookSoundFirstDivBookauthor_end">...playing</div>}
                            </div>
                        </div>
                        <div className="sampleAudios">
                            <OverlayTrigger trigger={["hover", "hover"]} placement={(this.state.windowWidth < 700) ? "bottom" : "right"} overlay={this.renderSamplePopUp(1)}>
                                <div className="squarecard" onClick={() => {
                                    const event = {
                                        event: 'play_pause_button_click',
                                        button_label: 'play_pause'
                                      };
                                    TagManager.dataLayer({ dataLayer: event });
                                    this.playPauseSample(1)}}>
                                    <img className={(this.state.audioPlaying == 1) ? "sampleCoverImage_playing" : "sampleCoverImage"} src={'https://d278admdl7nfkq.cloudfront.net/sample-books/cover_1.jpg'} alt="cover_1" />
                                    <div className={(this.state.audioPlaying == 1) ? "samplemiddle_playing1" : "samplemiddle"}>
                                        <img className="sampleplaypause" src={require((this.state.audioPlaying == 1) ? '../../images/stop-blue.png' : '../../images/play-blue.png')} alt="play-blue1" />
                                    </div>
                                </div>
                            </OverlayTrigger>
                            <div className="sampleauthordiv">
                                <div className="bookSoundFirstDivBookauthor"> </div>
                                {this.state.audioPlaying == 1 && <div className="bookSoundFirstDivBookauthor_end">...playing</div>}
                            </div>
                        </div>
                        <div className="sampleAudios">
                            <OverlayTrigger trigger={["hover", "hover"]} placement={(this.state.windowWidth < 700) ? "bottom" : "left"} overlay={this.renderSamplePopUp(4)}>
                                <div className="squarecard" onClick={() => {
                                   const event = {
                                    event: 'play_pause_button_click',
                                    button_label: 'play_pause'
                                  };
                                TagManager.dataLayer({ dataLayer: event });
                                    this.playPauseSample(4)}}>
                                    <img className={(this.state.audioPlaying == 4) ? "sampleCoverImage_playing" : "sampleCoverImage"} src={'https://d278admdl7nfkq.cloudfront.net/sample-books/cover_4.jpg'} alt="cover_2" />
                                    <div className="squarecards"><img onClick={()=>{this.openSocialMedia('https://www.audible.com/pd/Unlimited-Energy-Audiobook/B09MJQP6L5?qid=1660311023&sr=1-3&ref=a_search_c3_lProduct_1_3&pf_rd_p=83218cca-c308-412f-bfcf-90198b687a2f&pf_rd_r=ZD6WE29E3M7ECJMFBE6A')}} className={(this.state.audioPlaying == 4) ? "sampleplaypauses" : "sampleplaypauses"} src={require('../../images/Audible_logo.png')}  alt="audible" /></div>
                                    <div className={(this.state.audioPlaying == 4) ? "samplemiddle_playing1" : "samplemiddle"}>
                                     {/* <img className={"sampleplaypauses"} src={require('../../images/Audible_logo.png')} alt="audible"  /> */}
                                     
                                        <img className="sampleplaypause" src={require((this.state.audioPlaying == 4) ? '../../images/stop-blue.png' : '../../images/play-blue.png')} alt="play-blue2" />
                                    </div>
                                </div>
                            </OverlayTrigger>
                            <div className="sampleauthordiv">
                                <div className="bookSoundFirstDivBookauthor"> </div>
                                {/* {this.state.audioPlaying == 4 && <div className="bookSoundFirstDivBookauthor_end">   <img onClick={()=>{this.openSocialMedia('https://www.audible.com/pd/Unlimited-Energy-Audiobook/B09MJQP6L5?qid=1660311023&sr=1-3&ref=a_search_c3_lProduct_1_3&pf_rd_p=83218cca-c308-412f-bfcf-90198b687a2f&pf_rd_r=ZD6WE29E3M7ECJMFBE6A')}} className="sampleplaypause" src={require('../../images/Audible_logo.png')} alt="audible" /></div>} */}
                                {this.state.audioPlaying == 4 && <div className="bookSoundFirstDivBookauthor_end">...playing</div>}
                            </div>
                        </div>
                        <div className="sampleAudios">
                            <OverlayTrigger trigger={["hover", "hover"]} placement={(this.state.windowWidth < 700) ? "bottom" : "left"} overlay={this.renderSamplePopUp(5)}>
                                <div className="squarecard" onClick={() => {
                                    const event = {
                                        event: 'play_pause_button_click',
                                        button_label: 'play_pause'
                                      };
                                    TagManager.dataLayer({ dataLayer: event });
                                    this.playPauseSample(5)}}>
                                    <img className={(this.state.audioPlaying == 5) ? "sampleCoverImage_playing" : "sampleCoverImage"} src={'https://d278admdl7nfkq.cloudfront.net/sample-books/cover_5.jpg'} alt="cover_3" />
                                    <div className="squarecards"><img onClick={()=>{this.openSocialMedia('https://www.audible.com/pd/Conscious-Coping-Audiobook/B0B69QPMCZ?qid=1660311078&sr=1-1&ref=a_search_c3_lProduct_1_1&pf_rd_p=83218cca-c308-412f-bfcf-90198b687a2f&pf_rd_r=7KV2FBSPEPTWF7A2KQT0')}} className={(this.state.audioPlaying == 4) ? "sampleplaypauses" : "sampleplaypauses"} src={require('../../images/Audible_logo.png')}  alt="audible" /></div>
                                    <div className={(this.state.audioPlaying == 5) ? "samplemiddle_playing1" : "samplemiddle"}>
                                        <img className="sampleplaypause" src={require((this.state.audioPlaying == 5) ? '../../images/stop-blue.png' : '../../images/play-blue.png')} alt="play-blue3" />
                                    </div>
                                </div>
                            </OverlayTrigger>
                            <div className="sampleauthordiv">
                                <div className="bookSoundFirstDivBookauthor"> </div>
                                {/* {this.state.audioPlaying == 5 && <div className="bookSoundFirstDivBookauthor_end">   <img onClick={()=>{this.openSocialMedia('https://www.audible.com/pd/Conscious-Coping-Audiobook/B0B69QPMCZ?qid=1660311078&sr=1-1&ref=a_search_c3_lProduct_1_1&pf_rd_p=83218cca-c308-412f-bfcf-90198b687a2f&pf_rd_r=7KV2FBSPEPTWF7A2KQT0')}} className="sampleplaypause" src={require('../../images/Audible_logo.png')} alt="audible" /></div>} */}
                                {this.state.audioPlaying == 5 && <div className="bookSoundFirstDivBookauthor_end">...playing</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="publish_banner_container">
                    <div className="Listen_text publish_banner_title">Publish your audiobooks with an effortless transition to the best platforms in the market.</div>
                    <div className="publish_banner_caption">AUDiFYZ values your creation to be brought to fruition. We assist in your publishing efforts every step of the way. We encourage versatility and are proud of the compatibility across most distribution platforms.</div>
                    <img className="publish_banner_img" src={require('../../images/banner.png')} alt="banner" />
                </div>
                <div className="Listen" style={{ marginTop: '40px' }} id="pricing">
                    <li className="Listen_text">Pricing</li>
                    <li className="listen_caption">Choose a plan that's right for your requirement.</li>
                </div>
                {/* <div className="homepagePricingbannerDiv">
                    <img className="homepage_pricing_banner" 
                    src={'https://'+env.s3BucketConfig.bucketName+'.s3-us-west-2.amazonaws.com/public/website/home/pricingbanners/priceBanner_0.jpg?timestamp = ' + new Date().getTime()} 
                    alt="pricing_banner" />
                </div> */}
                <div className="homepagePricingMainDiv">
                    {
                        this.state.pricingData && this.state.pricingData.map((item, index) => {
                            return (
                                this.renderPlans(item, index)
                            );
                        })
                    }
                </div>
                <div>
                <div className="publish_partner_container">
                    <div className="Listen_text publish_partner_title">Our trusted partners</div>
                    
                        <div className="homepage_Partnerfoli" id="content">
                    
                            <img onClick={() => this.openSocialMedia('https://bublish.com/')} className="homepage_PartnerCard" src={require('../../images/Bublish_Logo_003.jpg')} alt="bublish" />
                       
                            <img onClick={() => this.openSocialMedia('https://www.dropcap.com/')} className="homepage_PartnerCard" src={require('../../images/Dropcap_Logo_003.jpg')} alt="dropcap" />
                        
                            <img onClick={() => this.openSocialMedia('https://jeankelley.com/')} className="homepage_PartnerCard" src={require('../../images/JeanKelley-Logo_003.jpg')} alt="jeankelly" />
                       
                            <img onClick={() => this.openSocialMedia('https://www.pursepower.com/')} className="homepage_PartnerCard" src={require('../../images/PursePower_Logo_003.jpg')} alt="pursepower" />
                        
                        </div>
                    </div>
                    </div>
                {/*delete after UI gets confirmed*/}
                {/* <div className="homepagePricingMainDiv">
                    <div className="homepageBasicPriceCardStyle">
                        <li className="homepagePriceingTypesHeading">Basic</li>
                        <div className="homepage_plancard_pricediv">
                            <div className="homepagepriceDoller">
                                <div className="homepagepriceDollerSign">&#36;</div>
                                <div className="homepagePrice">199</div>
                                <div className="homepagepriceDollerSign_Zero">.00</div>
                            </div>
                            <div className="homepagePriceperbook">per book</div>
                        </div>
                        <li className="homepagePriceingFeatures">All features including - </li>
                        <div className="homepagepricingDetails">
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Uploading books</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Extra narration</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Recording audio</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Digital viewing</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Self auto rectifications</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/not.png')} alt="not" />
                                <li className="homepagePriceingFeaturesDis">Audio engineer support</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/not.png')} alt="not" />
                                <li className="homepagePriceingFeaturesDis">Content retention</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/not.png')} alt="not" />
                                <li className="homepagePriceingFeaturesDis">Publishing efforts</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Customer support</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Chat support</li>
                            </div>
                        </div>
                        <div className="homepage_portReadmoremainDiv" style={{ marginBottom: '10px' }}>
                            <li onClick={()=>{this.navigationWithOutProps('/projects')}} className="homepage_portBrowse hvr-sweep-to-top">
                                Build now
                            </li>
                        </div>
                    </div>
                    <div className="homepageBasicPriceCardStyle">
                        <li className="homepagePriceingTypesHeading">Standard</li>
                        <div className="homepage_plancard_pricediv">
                            <div className="homepagepriceDoller">
                                <div className="homepagepriceDollerSign">&#36;</div>
                                <div className="homepagePrice">399</div>
                                <div className="homepagepriceDollerSign_Zero">.00</div>
                            </div>
                            <div className="homepagePriceperbook">per book</div>
                        </div>
                        <li className="homepagePriceingFeatures">All features including - </li>
                        <div className="homepagepricingDetails">
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Uploading books</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Extra narration</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Recording audio</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Digital viewing</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Self auto rectifications</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Audio engineer support</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/not.png')} alt="not" />
                                <li className="homepagePriceingFeaturesDis">Content retention</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/not.png')} alt="not" />
                                <li className="homepagePriceingFeaturesDis">Publishing efforts</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Customer support</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Chat support</li>
                            </div>
                        </div>
                        <div className="homepage_portReadmoremainDiv" style={{ marginBottom: '10px' }}>
                            <li onClick={()=>{this.navigationWithOutProps('/projects')}} className="homepage_portBrowse hvr-sweep-to-top">
                                Build now
                            </li>
                        </div>
                    </div>
                    <div className="homepageBasicPriceCardStyle">
                        <li className="homepagePriceingTypesHeading">Premium</li>
                        <div className="homepage_plancard_pricediv">
                            <div className="homepagepriceDoller">
                                <div className="homepagepriceDollerSign">&#36;</div>
                                <div className="homepagePrice">599</div>
                                <div className="homepagepriceDollerSign_Zero">.00</div>
                            </div>
                            <div className="homepagePriceperbook">per book</div>
                        </div>
                        <li className="homepagePriceingFeatures">All features including - </li>
                        <div className="homepagepricingDetails">
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Uploading books</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Extra narration</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Recording audio</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Digital viewing</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Self auto rectifications</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Audio engineer support</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Content retention</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Publishing efforts</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Customer support</li>
                            </div>
                            <div className="homepagePriceFeaturesDiv">
                                <img className="homepagepricingTick" src={require('../../images/correct.png')} alt="tick" />
                                <li className="homepagePriceingFeaturesDis">Chat support</li>
                            </div>
                        </div>
                        <div className="homepage_portReadmoremainDiv" style={{ marginBottom: '10px' }}>
                            <li onClick={()=>{this.navigationWithOutProps('/projects')}} className="homepage_portBrowse hvr-sweep-to-top">
                                Build now
                            </li>
                        </div>
                    </div>
                </div> */}
                <Footer />
            </div>
        );
    }

}

export default HomePage;