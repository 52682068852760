import React from 'react';
import { Modal } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Loader from 'react-loader-spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import 'bootstrap/dist/css/bootstrap.min.css';
import './exitfeedback.css'

import env from '../../env/envSelection'

//connect to Store

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setAuth from '../../data/actions/setAuth'

function mapStateToProps(state) {
  return {
    SETAUTH: state.setAuthReducer
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setAuth
  }, dispatch);
}

class ExitFeedback extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      exitFeedbackShown: false,
      exitFeedbackTrigger: props.exitFeedbackTrigger ? props.exitFeedbackTrigger : false,
      sessionTimer: props.sessionTimer,
      callModal: props.callModal ? props.callModal : false,
      email: '',
      name: '',
      loading: false,
    }
  }

  componentDidMount() {
    // this.checkLogin();
    this.checkSession();
    window.addEventListener('mousemove', (e) => this.checkMouseLeave(e))
  }

  checkLogin() {
    this.setState((state, props) => {
      return {
        isLoggedIn: props.SETAUTH.isAuth,
      }
    })
  }

  submitDetails() {
    this.setState({ loading: true })
    var path = '/callschedule/add' + '?timestamp = ' + new Date().getTime();
    fetch(env.audifyzdomain + path, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'x-api-key': 'c3VyZmF0cmlwLWtleQ==',
      },
      body: JSON.stringify({
        "username": this.state.name,
        "email": this.state.email,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then(response => {
        this.setState({ loading: false, callModal: false })
        this.props.onScheduleEvent();
      })
      .catch((err) => {
        this.setState({ loading: false })
        console.log(err)
      })
  }

  checkSession() {
    var exitFeedbackShown = window.sessionStorage.exitFeedbackShown;
    var sessionTimer = window.sessionStorage.sessionTimer;
    if (!exitFeedbackShown || exitFeedbackShown == 'false') {
      window.sessionStorage.setItem('exitFeedbackShown', 'false');
      this.setState({
        exitFeedbackShown: false,
        sessionTimer: (!sessionTimer || sessionTimer == undefined || sessionTimer == '') ? '' : sessionTimer
      })
      if (!sessionTimer || sessionTimer == undefined || sessionTimer == '') {
        setTimeout(() => this.sessionTimerFunc('22'), 22000);
        // setTimeout(()=>this.sessionTimerFunc('20'),20000);
      } else if (sessionTimer == '22') {
        // setTimeout(()=>this.sessionTimerFunc('20'),10000);
      }

    }
  }

  sessionTimerFunc(time) {
    window.sessionStorage.setItem('sessionTimer', time);
    // console.log('here'+time)
    this.setState({ sessionTimer: time })
  }

  checkMouseLeave(e) {
    // console.log('Mouse-X',e.clientX)
    // console.log('Mouse-y',e.clientY)
    // console.log(this.state.exitFeedbackShown)
    // console.log('timer',this.state.sessionTimer)
    // console.log('setAuth',this.props.SETAUTH.isAuth);
    if (!this.props.SETAUTH.isAuth && e.clientY < 25 && !this.state.exitFeedbackShown) {
      if (this.state.sessionTimer == '22') {
        this.setState({
          callModal: true
        })
      } else if (this.state.sessionTimer == '330') {
        //setstate for 2ndmodal
        this.setState({
          qnsModal: true
        })
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(() => this.sessionTimerFunc)
    window.removeEventListener('mousemove',e => this.checkMouseLeave(e));
  }

  handleModalShow() {
    window.sessionStorage.setItem('exitFeedbackShown', 'true');
    this.setState({ exitFeedbackShown: true })
  }

  handleCallFeedback() {
    this.props.onScheduleEvent();
    this.setState((state, props) => {
      return {
        callModal: false
      }
    })
  }

  renderCallFeedback() {
    return (
      <Modal
        size="lg"
        animation={true}
        centered
        backdrop={'static'}
        className="exitfeedback_modal"
        onShow={() => this.handleModalShow()}
        show={this.props.exitFeedbackTrigger ? true : this.state.callModal} onHide={() => this.handleCallFeedback()}>
        <div className="exitfeedback_container">
          <OverlayTrigger
            delay={{ show: 180, hide: 300 }}
            key={'close'}
            placement={'right'}
            overlay={
              <Tooltip id={`closeinactiveModal`}>
                Close
              </Tooltip>
            }>
            <img onClick={() => this.handleCallFeedback()} className="popclose_closeImage" src={require('../../images/close.png')} alt="close" />
          </OverlayTrigger>
          <div className="popclose_innerdiv">
            <div className="logoheading_horizontal">
              <img className="popup_logo" src={require('../../images/logo_audifyz_copy.png')} alt='logo' />
              <div className="popup_heading">AUDiFYZ</div>
            </div>
            <div className="popup_title popup_horiz_separator">Any questions? Schedule a live demo or a call with us.</div>
            <div className="popup_title2">Give us your details and we are happy to help you get to know our platform better.</div>
            <input className="popup_textinput popup_horiz_separator" placeholder="Enter name here." value={this.state.name}
              onChange={evt => this.setState({ name: evt.target.value })} type="text" />
            <input className="popup_textinput" placeholder="Enter email address here." value={this.state.email}
              onChange={evt => this.setState({ email: evt.target.value })} type="text" />
            <div onClick={() => this.submitDetails()}
              className={(this.state.loading || this.state.email.length < 6) ? "popup_submit popup_horiz_separator disabled_button" : "popup_submit popup_horiz_separator hvr-sweep-to-top"}>
              {
                this.state.loading ?
                  <Loader type="ThreeDots" color="#59cbb7"
                    width={60} height={60} />
                  :
                  'Submit'
              }
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  handleQnsFeedback() {
    this.setState((state, props) => {
      return {
        qnsModal: false
      }
    })
  }

  renderQnsFeedback() {
    return (
      <Modal
        size="lg"
        animation={true}
        centered
        className="exitfeedback_modal"
        onShow={() => this.handleModalShow()}
        show={this.state.qnsModal} onHide={() => this.handleQnsFeedback()}>
        <div className="exitfeedback_container">
          <OverlayTrigger
            delay={{ show: 180, hide: 300 }}
            key={'close'}
            placement={'right'}
            overlay={
              <Tooltip id={`closeinactiveModal`}>
                Close
              </Tooltip>
            }>
            <img onClick={() => this.handleQnsFeedback()} className="popclose_closeImage" src={require('../../images/closeCreate.png')} alt="closeCreate" />
          </OverlayTrigger>
          <div className="popclose_innerdiv">
            <div className="logoheading_horizontal">
              <img className="popup_logo" src={'https://d278admdl7nfkq.cloudfront.net/Images/logo_audifyz.png'} alt='logo_audifyz' />
              <div className="popup_heading">AUDiFYZ</div>
            </div>
            <div className="popup_title">Main title to be displayed in the popup.</div>
            <div className="popup_title2">Enter your mail, we'll call you</div>
            <input className="popup_textinput popup_horiz_separator" placeholder="Enter name here." value={this.state.name}
              onChange={evt => this.setState({ name: evt.target.value })} type="text" />
            {/* <input className="popup_textinput" placeholder="Enter email address here." value={this.state.email}
                          onChange={evt => this.setState({ email: evt.target.value })} type="text" /> */}
            <div className="popup_submit popup_horiz_separator hvr-sweep-to-top-heading">Submit</div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    return (<div>
      {this.renderCallFeedback()}
      {this.renderQnsFeedback()}
    </div>)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExitFeedback);