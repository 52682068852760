import React from 'react';
import './BlackFridaySale.css';
import '../Home/Home.css';
import ReactPixel from 'react-facebook-pixel';
import { navigateToUrl } from '../../utils/Navigating';
import { withRouter } from 'react-router-dom';

const advancedMatching = {};
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init('1052253578677047', advancedMatching, options);
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');

class BlackFridaySaleBadge extends React.Component {
  constructor(props) {
    super(props);
  }

  navigate(url) {
    var urlObj = {
      pathname: url,
    };
    navigateToUrl(this.props, urlObj);
  }

  render() {
    return (
      <div className="tollfree_navbar header-style">
        <table className="table-style">
          <tr>
            <td className="td-style">
              <a href="tel:+18003403530" style={{ color: 'white' }}>
                <img
                  className="homepageChat_img"
                  style={{ width: '16px', height: '16px', marginRight: '5px' }}
                  src={require('../../images/telephone-white.png')}
                  alt="telephone-blue"
                />
                Talk to our audifyz experts: 1-800-340-3530
              </a>
            </td>
            <td className="td-style">
              <div
                className="offer-badge"
                onClick={() => this.navigate('/black-friday-offers')}
              >
                <span style={{ lineHeight: '1.2' }}>
                  <span class="bold headerHeading">BLACK <span class="headerHeading-part-2">FRIDAY</span></span>
                </span>
              </div>
            </td>
            <td className="td-style">
              <div
                className="offer-badge"
                onClick={() => this.navigate('/black-friday-offers')}
              >
                <img
                  class="-funding-topbar-icon"
                  style={{ margin: '0 -50px', color: 'antiquewhite', height: '72px' }}
                  src={require('../../images/banner-BF-icon_03.png')}
                  alt="Black Friday 2023 - The biggest offer of the year"
                />
              </div>
            </td>
            <td className="td-style">
              <div
                className="offer-badge"
                onClick={() => this.navigate('/black-friday-offers')}
              >
                <span class="lw-white-text">
                  Don't miss out on the biggest sale of the year!
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default withRouter(BlackFridaySaleBadge);