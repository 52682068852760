import React, { Component } from "react";
import "./Logins.css";
import Fade from "react-reveal/Fade";
import LoadingSpinner from "../Loading/Loadingsm";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { trackPromise } from 'react-promise-tracker';
import Loading from "../Loading/ConstanLoading";
import { Redirect } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import Spinner from 'react-bootstrap/Spinner';

//connect to Store
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import userLogin from "../../data/actions/loginUser";
import setAuth from '../../data/actions/setAuth';
import config from '../../env/envSelection';

import env from '../../env/envSelection'
import ReactPixel from 'react-facebook-pixel';
const advancedMatching = {}; 
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init('1052253578677047', advancedMatching, options);
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');

//mapping state
function mapStateToProps(state) {
  return {
    USER_LOGIN: state.loginUserreducer,
    SETAUTH: state.setAuthReducer
  };
}

//mapping props
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userLogin,
      setAuth
    },
    dispatch
  );
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      login_selected: true,
      showLoading: false,
      buttonLoading: false,
      showUserRegModal: false,
      showPassResetModal: false,
      resetMailToggle: false,
      resetMailLoading: false,

      //Login Section Variables
      userLoginEmail: '',
      userLoginEmailError: '',
      userLoginPassword: '',
      userLoginError: '',
      isLoggingIn: false,

      //Restpass Variables
      passResetEmailMessage: '',
      passResetEmailMessageExists: false,

      //Registration Variables
      userRegEmail: '',
      userRegPassword: '',
      passworderror: '',
      registerpassword: '',
      validRegPassword:null,
      confirmpassword: '',
      username: '',
      usernameError: '',
      phone_number: '',
      phonenumbererror: '',
      registererror: '',

      //google and fb
      provider: '',
      emailMandatory: false,
      mail_mandatory: '',
      mail_mandatory_text: '',
      updateApi_Button: false,
      mailUpdateApi_Loading: false,
      is_verified: 1
    };
  }

  componentDidMount() {
    // console.log(this.props);
  }

  static getDerivedStateFromProps(nextprops, prevstate) {
    if (prevstate.isLoggingIn && nextprops.USER_LOGIN.data && nextprops.USER_LOGIN.data.length > 0) {
      localStorage.setItem('user_data', JSON.stringify(nextprops.USER_LOGIN.data[0]));
      localStorage.setItem('session_time', new Date().getTime());
      nextprops.setAuth(true, nextprops.USER_LOGIN.data[0]);
      return {
        redirectToReferrer: true,
        userLoginError: "",
        isLoggingIn: false,
      };
    } else if (prevstate.isLoggingIn && nextprops.USER_LOGIN.data && nextprops.USER_LOGIN.data.length === 0) {
      return {
        userLoginError: "Incorrect mail id or password",
        isLoggingIn: false
      };
    }
    return null;
  }

  onLoginSelect() {
    this.setState((state, props) => {
      return {
        login_selected: true,
      };
    });
  }

  onRegisterSelect() {
    this.setState((state, props) => {
      return {
        login_selected: false,
      };
    });
  }

  emailVerificationFunction(id) {
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var email = (id == 0) ? this.state.userLoginEmail : this.state.userRegEmail;
    if (regex.test(String(email).toLowerCase())) {
      if (id == 0) {
        this.checkMailExistence(0);
        this.setState((state, props) => {
          return {
            passResetEmailMessage: '',
            passResetEmailMessageExists: false
          }
        })
      } else {
        this.checkMailExistence(1);
        this.setState((state, props) => {
          return {
            userRegEmailError: ''
          }
        })
      }
    } else {
      if (id == 0) {
        this.setState((state, props) => {
          return {
            passResetEmailMessage: 'Invalid mail id',
            passResetEmailMessageExists: false,
          }
        })
      } else {
        this.setState((state, props) => {
          return {
            userRegEmailError: 'Invalid mail id',
          }
        })
      }
    }

  }

  onLogin() {
    if (this.validateLoginButton()) {
      this.setState((state, props) => {
        return {
          isLoggingIn: true,
        };
      });
      this.props.userLogin(this.state.userLoginEmail, this.state.userLoginPassword);
    }
  }

  onSignUp() {
    // console.log('sign up')
    this.setState((state, props) => {
      return {
        showLoading: true,
        isLoggingIn: false
      };
    });
    var path = '/user/signup' + '?timestamp = ' + new Date().getTime();
    fetch(env.audifyzdomain+path, {
      method: 'POST',
      headers:{
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'x-api-key': 'c3VyZmF0cmlwLWtleQ==',
      },
      body: JSON.stringify({
        "email": this.state.userRegEmail,
        "phone_no": this.state.phone_number,
        "password_hash": this.state.registerpassword,
        "profile_name": this.state.username,
        "pro_url": null,
        "url": window.location.origin + "/verification/",
        "provider": "HOST"
      }),
      })
      .then((response) => {
        if (response.headers.get('content-type').match(/application\/json/)) {
          return response.json();
        }
        return response;
      })
      .then((jsonresponse) => {
        // console.log(jsonresponse)
        this.setUserRegistration(jsonresponse)
      })
      .catch((error) => {
          console.log(error)
      })
  }

  setUserRegistration(response) {
    if (!this.state.isLoggingIn && response && response.length > 0) {
      localStorage.setItem('user_data', JSON.stringify(response[0]));
      localStorage.setItem('session_time', new Date().getTime());
      this.props.setAuth(true, response[0]);
      this.setState((state, props) => {
        return {
          showLoading: false,
          showUserRegModal: true,
          registererror: ""
        };
      });
    } else if (response && response.length === 0) {
      this.setState((state, props) => {
        return {
          showLoading: false,
          registererror: "Failed to register"
        };
      });
    }
  }

  validateLoginButton() {
    if (this.state.userLoginPassword !== "" && this.state.userLoginEmail != "" && this.state.userLoginEmailError == "") {
      return true;
    } else {
      return false;
    }
  }

  validateSignUp() {
    var username = this.state.username !== "" && this.state.username.replace(/\s/g, '').length != 0 ? true : false;
    var email = this.state.userRegEmail !== "" && this.state.userRegEmailError == '' ? true : false;
    var phonenumber = this.state.phone_number !== "" && this.state.phonenumbererror === '' ? true : false;
    var password = this.state.registerpassword !== "" && this.state.registerpassword === this.state.confirmpassword && this.state.validRegPassword ? true : false;
    if (username && email && password && phonenumber && !this.state.buttonLoading) {
      return true;
    } else {
      return false;
    }
  }

  checkPasswordMatch() {
    if (this.state.registerpassword.length !== 0 && this.state.confirmpassword.length !== 0) {
      if (this.state.registerpassword === this.state.confirmpassword) {
        this.setState((state, props) => {
          return {
            passworderror: ''
          }
        })
      } else {
        this.setState((state, props) => {
          return {
            passworderror: 'Passwords not matching'
          }
        })
      }
    }
  }

  usernameVerification() {
    if (this.state.username.startsWith(" ") || this.state.username == '') {
      this.setState((state, props) => {
        return {
          usernameError: 'Invalid username'
        }
      })
    } else {
      this.setState((state, props) => {
        return {
          usernameError: ''
        }
      })
    }
  }

  phonenumberVerification() {
    var phnumber = this.state.phone_number.trim()
    this.setState((state, props) => {
      return {
        phone_number: phnumber
      }
    })
    var regex = /\+?([\d|\(][\h|\(\d{3}\)|\.|\-|\d]{4,}\d)/
    if (regex.test(this.state.phone_number)) {
      this.setState((state, props) => {
        return {
          phonenumbererror: ''
        }
      })
    } else {
      this.setState((state, props) => {
        return {
          phonenumbererror: "Invalid phone no"
        }
      })
    }
  }

  checkMailExistence(id) {
    var emailToCheck = (id == 0) ? this.state.userLoginEmail : this.state.userRegEmail;
    var path = '/user/availability' + '?timestamp = ' + new Date().getTime();
    trackPromise(
      fetch(env.audifyzdomain+path, {
        method: 'POST',
        headers:{
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'x-api-key': 'c3VyZmF0cmlwLWtleQ==',
      },
        body: JSON.stringify({
          "email": emailToCheck,
        }),
      })
        .then(response => {
          return response.json();
        })
        .then(response => {
          if (id == 0) {
            this.setState((state, props) => {
              return {
                passResetEmailMessage: (response == 1) ? 'Existing email' : "Email does not exist",
                passResetEmailMessageExists: (response == 1) ? true : false,

              }
            })
          } else {
            this.setState((state, props) => {
              return {
                userRegEmailError: (response == 1) ? 'Email already exists' : '',
              }
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
      , 'check_email_area')
  }

  resetPassword() {
    this.setState({ resetMailLoading: true })
    var path = '/user/pass/reset' + '?timestamp = ' + new Date().getTime();
      fetch(env.audifyzdomain+path, {
        method: 'POST',
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-api-key': 'c3VyZmF0cmlwLWtleQ==',
        },
        body: JSON.stringify({
          "email": this.state.userLoginEmail,
          "url": window.location.origin + "/resetpass/"
        }),
      })
      .then(response => {
        return response.json();
      })
      .then(response => {
        this.setState({
          resetMailToggle: true,
          resetMailLoading: false
        })
      })
      .catch((error) => {
          console.log(error)
      })
  }

  validateLoginEmail() {
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(String(this.state.userLoginEmail).toLowerCase())) {
      this.setState({ userLoginEmailError: '' })
    } else {
      this.setState({ userLoginEmailError: 'Invalid mail id' })
    }
  }

  handleUserRegModal() {
    this.setState((state, props) => {
      return {
        showUserRegModal: !state.showUserRegModal,
        redirectToReferrer: true
      }
    })
  }


  renderUserRegModal() {
    return (
      <Modal
        size="lg"
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.showUserRegModal} onHide={() => this.handleUserRegModal()}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
            <div style={{ alignSelf: 'right', color: 'blue',}}>
              <OverlayTrigger
              delay={{ show: 250, hide: 150 }}
              key={'closeallMerge'}
              placement={'right'}
              overlay={
                  <Tooltip id={`closebuttonAllamerge`}>
                      Close
                  </Tooltip>
              }>
                  <img onClick={() => this.handleUserRegModal({})} className="loginUserred_CloseButton" src={require('../../images/close.png')} alt="close" />
              </OverlayTrigger>
            </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="projectCreation_DocumentuploadModal">
          <li className="projectCreation_DocumentuploadModalHeading">User registration</li>
          {/* <li className="projectCreation_DocumentuploadModalsubheading">Upload Your Book Here</li> */}
          <li className="projectCreation_DocumentuploadModalnote">Please check your registered email to access the verification link.</li>
          <div
            onClick={() => this.handleUserRegModal()}
            className="hvr-sweep-to-top login_userregmodal_buttom">
            Ok
          </div>
        </div>
      </Modal>
    );
  }

  handlePassResetModal() {
    if (!this.state.showPassResetModal) {
      this.emailVerificationFunction(0);
    }
    this.setState((state, props) => {
      return {
        showPassResetModal: !state.showPassResetModal,
        resetMailToggle: false
      }
    })
  }

  validateResetMail() {
    if (this.state.passResetEmailMessageExists && this.state.userLoginEmail != '') {
      return true
    }
    return false
  }


  onUserRegPassChange(text) {
    this.setState({
      registerpassword: text,
      passworderror:(this.state.confirmpassword.length>0 && this.state.confirmpassword != text )? "Passwords not matching" : ""
    })
  }

  onResetPassEmailChange(text) {
    this.setState((state, props) => {
      return {
        userLoginEmail: text,
        userLoginEmailError: '',
        userLoginError: '',
        passResetEmailMessageExists: false,
        passResetEmailMessage: '',
      }
    })
  }

  renderPassResetModal() {
    return (
      <Modal
        size="lg"
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.showPassResetModal} onHide={() => this.handlePassResetModal()}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
          <div style={{ alignSelf: 'right', color: 'blue',}}>
            <OverlayTrigger
              delay={{ show: 250, hide: 150 }}
              key={'closeallMerge'}
              placement={'right'}
              overlay={
                  <Tooltip id={`closebuttonAllamerge`}>
                      Close
                  </Tooltip>
              }>
                  <img onClick={() => this.handlePassResetModal({})} className="loginUserred_CloseButton" src={require('../../images/close.png')} alt="close" />
              </OverlayTrigger>
            </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="projectCreation_DocumentuploadModal">
          <li className="projectCreation_DocumentuploadModalHeading">Password reset</li>
          {/* <li className="projectCreation_DocumentuploadModalsubheading">Upload Your Book Here</li> */}
          {!this.state.resetMailToggle &&
            <div className="passwordresetmodal_innerdiv">
              <div style={{ width: '100%', margin: '3% 0%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>Email</label>
                  {this.state.passResetEmailMessage != '' && <label className={(this.state.passResetEmailMessageExists) ? "success_message" : "error_message"}>{this.state.passResetEmailMessage}</label>}
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <input maxLength="30" style={{ width: "100%" }} value={this.state.userLoginEmail} type="text" onBlur={() => this.emailVerificationFunction(0)}
                    onChange={evt => this.onResetPassEmailChange(evt.target.value)}
                    placeholder="Enter your email here..." />
                  <LoadingSpinner area="check_email_area" />
                </div>
              </div>
              {this.state.resetMailLoading ?
                <Loader type="Bars" color="#30217F" width={30} height={30} />
                :
                <div
                  onClick={() => this.resetPassword()}
                  className={(this.validateResetMail()) ? "login_userregmodal_buttom hvr-sweep-to-top" : "login_userregmodal_buttom_disabled"}>
                  Reset password
                </div>
              }
            </div>
          }
          {this.state.resetMailToggle &&
            <div className="passwordresetmodal_innerdiv">
              <li className="projectCreation_DocumentuploadModalnote">Please check your registered email to access your link.</li>
              <div
                onClick={() => this.handlePassResetModal()}
                className="login_userregmodal_buttom hvr-sweep-to-top">
                Ok
                </div>
            </div>
          }
        </div>
      </Modal>
    );
  }

  onUserLoginEmailChange(e) {
    e.persist();
    this.setState((state, props) => {
      return {
        userLoginEmail: e.target.value,
        userLoginEmailError: '',
        userLoginError: ''
      }
    })
  }

  onUserLoginPasswordChange(e) {
    e.persist();
    this.setState((state, props) => {
      return {
        userLoginPassword: e.target.value,
        userLoginError: ''
      }
    })
  }

  onUserRegEmailChange(text) {
    this.setState((state, props) => {
      return {
        userRegEmail: text,
        userRegEmailError: ' ',
      }
    })
  }

  googleandFacebookLogin(user_Data, provider) {
    var Email_id = '';
    var password_hash = '';
    var phone_no = '';
    var profile_name = '';
    var proImg_url = '';
    var email_error = 0;

    this.setState((state, props) => {
      return {
        provider: provider,
        showLoading: true
      }
    })

    if (user_Data && user_Data.email === undefined) {
      email_error = 1
    }

    if (provider === 'FACEBOOK' && user_Data) {
      if (email_error === 1) {
        Email_id = null
      }
      else {
        Email_id = user_Data.email;
      }
      password_hash = user_Data.id;
      phone_no = null;
      profile_name = user_Data.name;
      proImg_url = user_Data.picture && user_Data.picture.data.url;
    }

    if (provider === 'GOOGLE' && user_Data) {
      Email_id = user_Data.profileObj.email;
      password_hash = user_Data.profileObj.googleId;
      phone_no = null;
      profile_name = user_Data.profileObj.givenName;
      proImg_url = user_Data.profileObj.imageUrl;
    }

    this.setState((state, props) => {
      return {
        showLoading: true
      }
    })

    user_Data && fetch(env.audifyzdomain+'/user/signup/googlefb' + '?timestamp = ' + new Date().getTime(), {
      method: 'POST',
      headers:{
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'x-api-key': 'c3VyZmF0cmlwLWtleQ==',
    },
      body: JSON.stringify({
        "email": Email_id,
        "password_hash": password_hash,
        "phone_no": null,
        "profile_name": profile_name,
        "pro_url": proImg_url,
        "provider": provider
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then(jsonresponse => {
        this.setState((state, props) => {
          return {
            profile_id: jsonresponse[0].id,
            userToken: jsonresponse[0].token,
            is_verified: jsonresponse[0].is_verified,
          }
        })
        if (jsonresponse[0].is_verified === 0) {
          this.showemailMandatoryModal()
        } else {
          this.setUserRegistrationGoogleandFb(jsonresponse)
        }
      })
      .catch((err) => { console.log(err) })
  }

  responseGoogle = (response) => {
    // console.log(response)
    this.googleandFacebookLogin(response, 'GOOGLE')
    this.setState((state, props) => {
      return {
        accessToken: response.accessToken,
        provider_id: response.googleId
      }
    })
  }

  onFailure = (response) => {
    console.log('failure',response)
  }

  responseFacebook = (response) => {
    console.log(response)
    if(response.status === 'connected'){
      this.googleandFacebookLogin(response, 'FACEBOOK')
    }
  }

  componentClicked = (response) => {
    // console.log("fb", response)
  }

  setUserRegistrationGoogleandFb(response) {
    // console.log("dada")
    var user_Provider = [];
    user_Provider.push({ accessToken: this.state.accessToken, provider_id: this.state.provider_id })
    if (!this.state.isLoggingIn && response && response.length > 0) {
      localStorage.setItem('user_data', JSON.stringify(response[0]));
      localStorage.setItem('user_Provider_data', JSON.stringify(user_Provider));
      localStorage.setItem('session_time', new Date().getTime());
      this.props.setAuth(true, response[0]);
      if (this.state.provider === 'FACEBOOK') {
        if (this.state.is_verified === 1) {
          this.setState((state, props) => { return { showLoading: false, registererror: "", redirectToReferrer: true }; });
        } else {
          this.setState((state, props) => { return { showUserRegModal: true, showLoading: false, registererror: "" }; });
        }
      } else {
        this.setState((state, props) => { return { showLoading: false, registererror: "", redirectToReferrer: true }; });
      }
    } else if (response && response.length === 0) {
      this.setState((state, props) => { return { showLoading: false, registererror: "Failed to register" }; });
    }
  }

  showemailMandatoryModal() {
    this.setState((state, props) => {
      return {
        emailMandatory: !this.state.emailMandatory,
        showLoading: false
      }
    })
  }

  emailMandatoryModal() {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.emailMandatory} onHide={() => this.showemailMandatoryModal()}
        backdrop="static">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
          <div onClick={() => this.showemailMandatoryModal({})} style={{ alignSelf: 'right', color: 'blue', cursor: 'pointer' }}>
            <img className="loginUserred_CloseButton" src={require('../../images/close.png')} alt="close" />
          </div>
        </div>
        <div className="loginpage_emailMandatory_Main">
          <li className="projectCreation_DocumentuploadModalHeading">User registration</li>
          <div className="projectCreation_DocumentuploadModalnote" style={{ marginTop: '15px' }}>
            Email is mandatory for the purpose of user registration. So, please verify your email address.
          </div>
          <div className="loginpage_emailMandatory_inputarea_Main">
            <input className="loginpage_emailMandatory_inputarea" placeholder="Enter mail id" value={this.state.mail_mandatory}
              type="text" onChange={(evt) => this.emailVerificationFunction_emailMandatory(evt)} />
            <LoadingSpinner area="check_email_area" />
          </div>
          <div className="loginpage_emailMandatory_text">{this.state.mail_mandatory_text}</div>
        </div>
        <div className="loginpage_emailMandatory_Button_Main">
          {
            this.state.mailUpdateApi_Loading === false ?
              <div style={{ pointerEvents: (this.validatemailUpdateButton()) ? 'auto' : 'none' }}
                className="hvr-sweep-to-top loginpage_emailMandatory_Button" onClick={() => this.checkMailExistenceFunction()}>
                Register
              </div>
              :
              <div className="loginpage_emailMandatory_Button_loading">
                <Spinner className="FAQ_MailsupportForm_Spinner" animation="border" variant="light" />
              </div>
          }
        </div>
      </Modal>
    );
  }

  validatemailUpdateButton() {
    return (this.state.updateApi_Button && this.state.updateApi_Button === true) ? true : false;
  }

  validateRegPassword(){
    var text = this.state.registerpassword;
      var letterNumber = /^[0-9a-zA-Z]+$/;
      if((text.match(letterNumber)) && text.length >= 8){
        this.setState({validRegPassword:true})
      }else{ 
        this.setState({validRegPassword:false})
      }
  }

  emailVerificationFunction_emailMandatory(evt) {
    this.setState({ mail_mandatory: evt.target.value });
    // console.log(this.state.mail_mandatory)
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(String(evt.target.value).toLowerCase())) {
      this.setState((state, props) => {
        return {
          mail_mandatory_text: '',
          updateApi_Button: true,
        }
      })
    }
    else {
      this.setState((state, props) => {
        return {
          mail_mandatory_text: 'Wrong email address, please enter correct address',
          updateApi_Button: false
        }
      })
    }
  }

  checkMailExistenceFunction() {
    var path = '/user/availability' + '?timestamp = ' + new Date().getTime();
    trackPromise(
        fetch(env.audifyzdomain+path, {
          method: 'POST',
          headers:{
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'x-api-key': 'c3VyZmF0cmlwLWtleQ==',
          },
          body: JSON.stringify({
            "email": this.state.mail_mandatory,
          }),
      })
      .then(response => { return response.json(); })
        .then(response => {
          if (response === 1) {
            this.setState((state, props) => {
              return {
                mail_mandatory_text: 'email is already registered.',
                updateApi_Button: false
              }
            })
          } else {
            this.setState((state, props) => {
              return {
                mail_mandatory_text: '',
                updateApi_Button: true
              }
            })
            this.mailUpdateApi()
          }
        })
        .catch((err) => { console.log(err) }), 'check_email_area')
  }

  mailUpdateApi() {
    // console.log("here")
    // console.log(this.state.mail_mandatory, this.state.profile_id)
    this.setState((state, props) => {
      return {
        mailUpdateApi_Loading: true
      }
    })
    var path = '/user/email/update' + '?timestamp = ' + new Date().getTime();
      fetch(env.audifyzdomain+path, {
        method: 'POST',
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-api-key': 'c3VyZmF0cmlwLWtleQ==',
            'token': this.state.userdata.token,
        },
        body: JSON.stringify({
          "email": this.state.mail_mandatory,
          "id": this.state.profile_id,
          "url": window.location.origin + "/verification/"
        }),
      })
      .then((response) => {
        return response.json();
      })
      .then(jsonresponse => {
        // console.log(jsonresponse)
        this.showemailMandatoryModal()
        this.setState((state, props) => {
          return {
            mailUpdateApi_Loading: false
          }
        })
        this.setUserRegistrationGoogleandFb(jsonresponse)
      })
      .catch((error) => {
          console.log(error)
      })
  }

  renderLoginDiv() {
    return (
      <div className="logincontainer">
        {this.renderUserRegModal()}
        {this.renderPassResetModal()}
        {this.emailMandatoryModal()}
        <Fade timeout={1000} left>
          <div className="login_left">
            <h3 style={{ marginBottom: '2px' }}>AUDiFYZ</h3>
            <h5>Build your own audiobook</h5>
            <div>
              <GoogleLogin
                clientId={config.gClientId}
                buttonText="Login with Google"
                onSuccess={this.responseGoogle}
                className="btnGoogle"
                onFailure={this.onFailure}
                cookiePolicy={'single_host_origin'}
              />
            </div>
            <div>
              <FacebookLogin
                appId="281670896253462"
                autoLoad={false}
                cssClass='btnFacebook'
                fields="name,email,picture"
                onClick={this.componentClicked}
                callback={this.responseFacebook}
                onFailure={this.onFailure}
              // icon="fa-facebook" 
              />
            </div>
          </div>
        </Fade>
        <Fade timeout={1000} right>
          <div className="login_right">
            <div style={{ height: "20%", width: "85%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              <div
                onClick={() => this.onLoginSelect()}
                className={this.state.login_selected ? "unselected_button" : "selected_button hvr-sweep-to-top"}>
                Login
                </div>
              <div onClick={() => this.onRegisterSelect()} className={this.state.login_selected ?
                "selected_button hvr-sweep-to-top" : "unselected_button"}>
                Register
              </div>
            </div>
            {this.state.login_selected ? (
              <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
                <h4>Login</h4>
                <div style={{ marginBottom: "10%" }} />
                <label>Email</label>
                <input maxLength="35" value={this.state.userLoginEmail} onChange={evt => this.onUserLoginEmailChange(evt)} type="email" placeholder="Enter your email here..." onBlur={() => this.validateLoginEmail()} />
                <div style={{ marginBottom: "5%" }} className="error_message_login">
                  {
                    this.state.userLoginEmailError
                  }
                </div>
                <label>Password</label>
                <input maxLength="30" onChange={evt => this.onUserLoginPasswordChange(evt)} value={this.state.userLoginPassword} type="password"
                  placeholder="Enter your password here..." />
                <label className="error_message_login"> {this.state.userLoginError} </label>
                <div style={{ marginBottom: "10%" }} />
                <div style={{ pointerEvents: this.validateLoginButton() ? "auto" : "none" }} onClick={() => this.onLogin()}
                  className={this.validateLoginButton() ? "login_right_button hvr-sweep-to-top" : "login_right_button_disabled"}>
                  Sign in
                </div>
              </div>
            ) : (
                <div style={{ display: "flex", flexDirection: "column", width: "70%" }}>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <h4>Register</h4>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label>Username</label>
                      <label className="error_message"> {this.state.usernameError} </label>
                    </div>
                    <input maxLength="25" value={this.state.username} onBlur={() => this.usernameVerification()}
                      onChange={evt => this.setState({ username: evt.target.value })} type="text"
                      placeholder="Enter your username here..." />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label>Phone number</label>
                      <label className="error_message"> {this.state.phonenumbererror} </label>
                    </div>
                    <input maxLength="15" value={this.state.phone_number} onBlur={() => this.phonenumberVerification()}
                      onChange={evt => this.setState({ phone_number: evt.target.value })} type="text"
                      placeholder="Enter your phone number here..." />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label>Email</label>
                      {this.state.userRegEmailError != '' &&
                        <label className="error_message">{this.state.userRegEmailError}</label>
                      }
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <input maxLength="30" style={{ width: "100%" }} type="text" value={this.state.userRegEmail} onBlur={() => this.emailVerificationFunction(1)}
                        onChange={evt => this.onUserRegEmailChange(evt.target.value)}
                        placeholder="Enter your email here..." />
                      <LoadingSpinner area="check_email_area" />
                    </div>
                    <div className="password_container">
                      <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
                        <label>Password</label>
                        <input id="registerpasswordinput" maxLength="30"
                          onBlur={()=>{this.validateRegPassword()}}
                          onChange={evt => this.onUserRegPassChange(evt.target.value)}
                          value={this.state.registerpassword} type="password" placeholder="Your password here..." />
                          <div style={{display:(this.state.validRegPassword == false && this.state.registerpassword.length >0 && document.activeElement.id != 'registerpasswordinput')? 'block' : 'none'}} id="password_message">
                            <div>Must have alphabets and numbers,</div>
                            <div>Minimum 8 characters</div>
                          </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
                        <label>Confirm password</label>
                        <input maxLength="30" onBlur={() => this.checkPasswordMatch()} onChange={evt => this.setState({
                          confirmpassword: evt.target.value,
                          passworderror: ""
                        })}
                          value={this.state.confirmpassword} type="password" placeholder="Confirm your password" />
                        <label className="error_message">{this.state.passworderror}</label>
                      </div>
                    </div>
                  </div>
                  <label className="error_message_login"> {this.state.registererror} </label>
                  <div style={{ pointerEvents: this.validateSignUp() ? "auto" : "none" }}
                    onClick={() => this.onSignUp()}
                    className={this.validateSignUp() ? "login_right_button hvr-sweep-to-top" : "login_right_button_disabled"}>
                    Sign up
                    </div>
                </div>
              )}
            <div
              style={{
                display: "flex",
                width: "60%",
                justifyContent: "space-between",
                marginTop: "2%"
              }}
            >
              {this.state.login_selected && (
                <p onClick={() => { this.handlePassResetModal() }} className="forgotpassword">Forgot password?</p>
              )}
              {this.state.login_selected ? (
                <p
                  onClick={() => this.onRegisterSelect()}
                  className="forgotpassword"
                >
                  Not a member yet?{" "}
                </p>
              ) : (
                  <p
                    onClick={() => this.onLoginSelect()}
                    className="forgotpassword"
                  >
                    Go to Sign in
                  </p>
                )}
            </div>
          </div>
        </Fade>
      </div>
    );
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;
    if (redirectToReferrer === true) {
      return <Redirect to={from} />;
    }
    return this.state.showLoading ? (
      <Loading />
    ) : (
        <div className="MainDiv">{this.renderLoginDiv()}</div>
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
