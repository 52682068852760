const gClientId = (window.location.host === 'dev.audifyz.com' || window.location.host === 'www.dev.audifyz.com') ?
    '869943330313-j45bap4pk65e72as3a98o3sqiajkfqj9.apps.googleusercontent.com'  //dev
    :'869943330313-6qo5u095ut2d64aml8qroo43empik6su.apps.googleusercontent.com' //production
const ppalId = (window.location.host === 'dev.audifyz.com' || window.location.host === 'www.dev.audifyz.com') ? 
    'AYx8VDMMeXgY_wW56rtZZf7omk7c5ucmxHkkDglHS2AM1JnIRXboiPW_bX5h2LTM2rNdaeh9mZPzbDS-' //dev
    :'AYxCLX7G5rY-2fzpszTpOpxKtli0Ksff86r-nznqMwd8zDNhKDxjLw2prbrlzj67rFjd618zyIrWsuyk'; //production
const authtoken = (window.location.host === 'dev.audifyz.com' || window.location.host === 'www.dev.audifyz.com') ? 'c3VyZmF0cmlwLWtleQ==' : 'c3VyZmF0cmlwLWtleQ==';
const audifyzdomain =
(window.location.host === 'dev.audifyz.com' || window.location.host === 'www.dev.audifyz.com') ?
        'https://devapi.audifyz.com'
        :
        'https://api.audifyz.com';
const s3BucketConfig = (window.location.host === 'dev.audifyz.com' || window.location.host === 'www.dev.audifyz.com') ? 
    {
        bucketName:'zdlab-audifyz-dev',
        expiry:7200,
        region:'us-west-2'
    }
     : {
        bucketName:'zdlab-audifyz-prod',
        expiry:7200,
        region:'us-west-2'
    };
    // console.log('CurrentHost',window.location)
export default {
    authtoken,
    audifyzdomain,
    s3BucketConfig,
    gClientId,
    ppalId
}

// const gClientId =
//     '869943330313-j45bap4pk65e72as3a98o3sqiajkfqj9.apps.googleusercontent.com'  //dev
// const ppalId = 
//     'AYx8VDMMeXgY_wW56rtZZf7omk7c5ucmxHkkDglHS2AM1JnIRXboiPW_bX5h2LTM2rNdaeh9mZPzbDS-' //dev
// const authtoken = 'c3VyZmF0cmlwLWtleQ==';
// const audifyzdomain =
//         'https://devapi.audifyz.com'
// const s3BucketConfig = 
//     {
//         bucketName:'zdlab-audifyz-dev',
//         expiry:7200,
//         region:'us-west-2'
//     }
//     // console.log('CurrentHost',window.location)
// export default {
//     authtoken,
//     audifyzdomain,
//     s3BucketConfig,
//     gClientId,
//     ppalId
// }