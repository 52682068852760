
  export const navigateToUrl = (props,urlObj) => {
    // console.log('navigateToUrl',props)
    // console.log('url',url)
    if(props.history.location.pathname == urlObj.pathname){
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }else{
        props.history.push({
            ...urlObj
        });
    }
  };