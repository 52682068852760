import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ProtectedRoute from './Protected/ProtectedRoute';
import ScrollToTop from './ScrollToTop';
import Loader from 'react-loader-spinner'


//Common Components
import './App.css';
import Toolbar from './components/Toolbar/Toolbar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import HomePage from './components/Home/Home';
import Backdrop from './components/Backdrop/Backdrop';
import LoadingSpinner from './components/Loading/Loading'
import ModalSpinner from './components/Loading/ModalLoading';
import ExitFeedback from './components/Exitfeedback/exitfeedback'

//css
import './components/VerifyAudio/VerifyAudio.css'
import './components/Login/Logins.css'
import './components/ResetPassword/ResetPassword.css'
import './components/verification/Verification.css'
import './components/Projects/Projects.css'
import './components/CreatenewBook/Create.css'
import './components/VerifyBook/bookCustomization.css'
import './components/OptimizeAudio/optimizeAudio.css'
import './components/Voices/Voice.css'
import './components/Readmore/Readmore.css'
import './components/FAQs/FAQs.css'
import './components/Payment/Payment.css'
import './components/VerifyAudio/VerifyAudio.css'
import './components/SampleRecording/SampleRecording.css'
import './components/Empty/Empty.css'
import './components/VideoPage/VideoPage.css'

//store
import { Provider } from 'react-redux';
import store from './Store';
import TagManager from 'react-gtm-module';
import ScheduleForDemo from './components/ScheduleDemo/scheduleDemo';
import BlackFridaySaleBadge from './components/BlackFridaySale/BlackFridaySaleBadge';

/// Screens
const Login = React.lazy(() => import('./components/Login/Login'));
const ResetPassword = React.lazy(() => import('./components/ResetPassword/ResetPassword'));
const Verification = React.lazy(() => import('./components/verification/Verification'));
const Projects = React.lazy(() => import('./components/Projects/Projects'));
const CreateNewProject = React.lazy(() => import('./components/CreatenewBook/Create'));
const VerifyBook = React.lazy(() => import('./components/VerifyBook/verifybook'));
const BookGrouping = React.lazy(() => import('./components/VerifyBook/bookGrouping'));
const OptimizeAudio = React.lazy(() => import('./components/OptimizeAudio/optimizeAudio'));
const Privacy = React.lazy(() => import('./components/Privacy/Privacy'));
const Voices = React.lazy(() => import('./components/Voices/Voices'));
const readmore = React.lazy(() => import('./components/Readmore/readmore'));
const readRecordmore = React.lazy(() => import('./components/Readmore/recodingMore'));
const FAQs = React.lazy(() => import('./components/FAQs/FAQs'));
const Payment = React.lazy(() => import('./components/Payment/Payment'));
const VerifyAudio = React.lazy(() => import('./components/VerifyAudio/VerifyAudio'));
const SampleRecording = React.lazy(() => import('./components/SampleRecording/SampleRecording'));
const QuesAndAns = React.lazy(() => import('./components/FAQs/emailSupport'));
const mailSupport = React.lazy(() => import('./components/FAQs/Q&AnswersPage'));
const chatSupport = React.lazy(() => import('./components/FAQs/chatSupport'));
const Empty = React.lazy(() => import('./components/Empty/Empty'));
const videoPage = React.lazy(()=> import('./components/VideoPage/VideoPage'));
const BlackFridaySale = React.lazy(() => import('./components/BlackFridaySale/BlackFridaySale'));





class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      exitFeedbackTrigger:false,
      list_id: '',
      isScheduleTabActive: false,
    };
    const tagManagerArgs = {
      gtmId: 'GTM-MQVGV8M9' 
    };
    TagManager.initialize(tagManagerArgs);
  }

  componentDidMount() {
    document.title = "AUDiFYZ"
  }

  getFromApiHandler() {
    fetch('/api_handler', {
      method: 'GET',
      headers: new Headers({
        'path': '/project/list',
        'headers': JSON.stringify({
          'token': "6cf68905e80556e7",
        }),
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
      })
      .catch((err) => { console.log(err) })
  }

  postToApiHandler() {
    fetch('/api_handler', {
      method: 'POST',
      headers: new Headers({
        'path': '/project/add',
        "Content-Type": "application/json",
        'headers': JSON.stringify({
          'token': "6cf68905e80556e7",
        }),
      }),
      body: JSON.stringify({
        "project_name": 'thallu_baaba_handlerprojectName',
        "description": 'api_handlerdescription',
        "language_id": 1
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
      })
      .catch((err) => { console.log(err) })
  }


  drawerToggleClickHandler = () => {
    this.setState((prevstate) => {
      return {
        sideDrawerOpen: !prevstate.sideDrawerOpen
      };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  };

  onToolbarListClick(id) {
    this.setState((state, props) => {
      return {
        list_id: id
      }
    })
    if (id == 2 && window.location.pathname === '/') {
      var elmnt = document.getElementById("pricing");
      elmnt.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    else {
    }
    if (id == 3 && window.location.pathname === '/') {
      var elmnt = document.getElementById("contact");
      elmnt.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  setExitFeedbackModalEnable = () => {
    // this.setState()
    this.setState((prevstate) => {
      return {
        exitFeedbackTrigger:true
      };
    });

  };

  setScheduleTabActive = (flag) => {
    this.setState((prevstate) => {
      return {
        isScheduleTabActive:flag
      };
    });
  };

  setExitFeedbackModalDisable = () => {
    // this.setState()
    this.setState((prevstate) => {
      return {
        exitFeedbackTrigger:false
      };
    });

  };

  render() {
    return (
      <div className="App" style={{ height: '100%' }}>
        <Suspense fallback={<div className="modal_spinner_new">
          <Loader type="Bars" color="#30217F" height={100} width={100} />
        </div>}>
          <ExitFeedback exitFeedbackTrigger={this.state.exitFeedbackTrigger} onScheduleEvent = {this.setExitFeedbackModalDisable}/>
          {/* <BlackFridaySaleBadge/> */}
          <a href="tel:+18003403530"><div className="tollfree_navbar"><img className="homepageChat_img" style={{ width: '16px', height: '16px',marginRight:'5px' }} src={require('./images/telephone-white.png')} alt="telephone-blue" />Talk to our audifyz experts: 1-800-340-3530</div></a>
          <Toolbar drawerClickHandler={this.drawerToggleClickHandler} onScheduleEvent = {this.setExitFeedbackModalEnable}
            onListpress={(click_items) => this.onToolbarListClick(click_items)} isScheduleTabActive = {this.state.isScheduleTabActive} />
          <SideDrawer show={this.state.sideDrawerOpen} click={this.backdropClickHandler} />
          {this.state.sideDrawerOpen && <Backdrop click={this.backdropClickHandler} />}
          <LoadingSpinner width="300" height="100" />
          <ModalSpinner area="modal_spinner_new" width="300" height="100" />
          <main style={{ marginTop: '90px', padding: '0', width: '100%', height: '100%' }}>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/login" component={Login} />
              <Route path="/empty" component={Empty} />
              <Route path="/resetpass/:hash?" component={ResetPassword} />
              <Route path="/verification/:hash?" component={Verification} />
              <ProtectedRoute path="/projects" component={Projects} />
              <ProtectedRoute path="/createnewproject" component={CreateNewProject} />
              <ProtectedRoute path="/verifybook/:userIdHash/:project_id?" component={VerifyBook} />
              <ProtectedRoute path="/BookGrouping/:userIdHash/:project_id?" component={BookGrouping} />
              <ProtectedRoute path='/voices/:userIdHash/:project_id?' component={Voices} />
              <ProtectedRoute path="/payment/:userIdHash/:project_id?" component={Payment} />
              <ProtectedRoute path="/verifyaudio/:userIdHash/:project_id?" component={VerifyAudio} />
              <ProtectedRoute path="/optimizeaudio/:userIdHash/:project_id?" component={OptimizeAudio} />
              <Route path="/how-to-make-an-audiobook-create-your-own" component={readmore} />
              <Route path="/schedule-for-demo" render={() => <ScheduleForDemo setScheduleTabActive={this.setScheduleTabActive} />}/>
              {/* <Route path="/black-friday-offers" component={BlackFridaySale} /> */}
              <Route path="/tips-for-recording-an-audiobook" component={readRecordmore} />
              <Route path="/audio-book-production" component={FAQs} />
              <Route path="/samplerecording" component={SampleRecording} />
              <Route path="/QuesAndAns/:qn_id?" component={QuesAndAns} />
              <ProtectedRoute path="/mailSupport" component={mailSupport} />
              <Route path="/chatSupport" component={chatSupport} />
              <Route path="/privacypolicy" component={Privacy} />
              <Route path="/VideoPage" component={videoPage} />
            </Switch>
          </main>
          </Suspense>
        </div>
    );
  }
}

function AppRouter() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </Provider>
  );
}

export default AppRouter;
