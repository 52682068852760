const ADD_NEW_CHAPTER = "ADD_NEW_CHAPTER";
const ADD_NEW_CHAPTER_SUCCESS = "ADD_NEW_CHAPTER_SUCCESS";
const ADD_NEW_CHAPTER_FAIL = "ADD_NEW_CHAPTER_FAIL";

const initialState = {
    data: {},
    isfetching: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_NEW_CHAPTER: {
            return {
                ...state,
                isfetching: true,
            }
        }
        case ADD_NEW_CHAPTER_SUCCESS: {
            return {
                ...state,
                isfetching: false,
                data: action.data,
            }
        }
        case ADD_NEW_CHAPTER_FAIL: {
            return {
                ...state,
                isfetching: false,
                fail: true,
            }
        }
        default: return state
    }
}