import React from 'react';
import { navigateToUrl } from '../../utils/Navigating';
import { withRouter } from 'react-router-dom';

class Footer extends React.Component{

    constructor(props){
        super(props);
        this.state={
            showButton:true,
        }
    }

    componentDidMount(){
        if (localStorage.getItem('user_data') && JSON.parse(localStorage.getItem('user_data')).token) {
            this.setState((state,props)=>{
                return{
                    showButton:false
                }
            })
        }
    }

    navigationWithOutProps(url) {
        var urlObj = {
            pathname: url,
        };
        navigateToUrl(this.props,urlObj);
    }

    openSocialMedia(url=''){
        window.open(url,"_blank")
    }

    render(){
        return(
            <div className="vrBooksMainStyle" id="contact">
                    <div className="homepagefooterDivFirstsec">
                        <div className="homepageFooterContactDiv">
                            <li className="hoempageFooterContusStyle">Contact us</li>
                            <li className="homepagecontactDetails">support@audifyz.com</li>
                            <li></li>
                            {/* <li className="homepagecontactDetails">www.zdistancelab.com</li> */}
                            <a href="tel:+18003403530" style={{all:'unset'}}>
                                <li className="homepagecontactDetails"> <img className="homepageChat_img" style={{width:'16px', height:'16px'}} src={require('../../images/telephone-white.png')} alt="telephone-white" /> Call Now 1-800-340-3530</li>
                            </a>
                            {/* <li className="homepagecontactDetails">+1 205-390-6744 (USA)</li>
                            <li className="homepagecontactDetails">+1 657-247-0295</li> */}
                            {/* <li className="homepagecontactDetails">+91 9895091409 (India)</li>
                            <li className="homepagecontactDetails">+91 9539534434</li> */}
                        </div>
                        <div className="homepagefooterFollowDiv">
                            <li className="homepagefooterfollow">Follow us on</li>
                            <div className="homepagefooterfollowimages">
                                <img onClick={()=>{this.openSocialMedia('https://www.facebook.com/Audifyz-104512548046609/')}} className="homepagefootersocialmedia" src={require('../../images/facebook.png')} alt="facebook" />
                                <img onClick={()=>{this.openSocialMedia('http://twitter.com/@audifyz')}} className="homepagefootersocialmedia_tw" src={require('../../images/twitter.png')} alt="twitter" />
                                <img onClick={()=>{this.openSocialMedia('https://www.youtube.com/channel/UClr81mSNez6LvRJL8tehA2w')}} className="homepagefootersocialmedia_yt" src={require('../../images/youtube.png')} alt="youtube" />
                            </div>
                        </div>
                        <div className="homepagefooterMain">
                            {this.state.showButton && <li className="homepageFooterAudifyz">Stay connected with us</li>}
                            {this.state.showButton && <li onClick={()=>this.navigationWithOutProps('/login')} className="homepagefootersubheading">Login</li>}
                            <div className="homepageFooterMobile">
                                {/*<img className="homepageFooterPlaystore" src={'https://d278admdl7nfkq.cloudfront.net/Images/playstore.png'} alt="tw" />
        <img className="homepageFooterapplestore" src={'https://d278admdl7nfkq.cloudfront.net/Images/applestore.png'} alt="tw" />*/}
                            </div>
                        </div>
                    </div>
                    <hr className="homepageStrightLine" width="100%"></hr>
                    <div className="homepagePolices">
                        {/* <li className="homepageCompany">Zedilab Software Systems</li> */}
                        <li className="homepageCompany">1126 Ponce De Leon Ave, Atlanta, Georgia, 30306, USA</li>
                        {/* <li className="homepageCompanyUSA">Kinfra Video park, Kazhakoottam , Trivandrum , India</li> */}
                        {/* <li>&nbsp; &amp; &nbsp;</li> */}
                    </div>
                    <hr className="homepageStrightLine" width="100%"></hr>
                    <div className="homepagePolices">
                        {/* <li className="homepagePoliceStyle">Privacy Policy</li> */}
                        <li>&copy; 2022 Larynx Innovations Inc. All rights reserved.</li>
                    </div>
                </div>
        );
    }
}

export default withRouter(Footer);