import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './SideDrawer.css'

class SideDrawer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    logOut() {
        localStorage.removeItem('user_data');
        window.location.reload();
    }


    openSocialMedia(url=''){
        window.open(url,"_blank")
    }
    

    navigate(url) {
        this.props.click()
        this.props.history.push({
            pathname: url,
        });
    }

    pricingPage() {
        this.props.click()
        if (window.location.pathname === "/") {
            this.scrollToMyRef('pricing');
        } else {
            this.props.history.push({
                pathname: '/',
                scroll_id: 'pricing',
            })
        }
    }

    scrollToMyRef(id, position = "center") {
        var elmnt = document.getElementById(id);
        elmnt.scrollIntoView({ behavior: "smooth", block: position, inline: 'nearest' });
    }

    onContactUsPress() {
        this.props.click()
        if (window.location.pathname === "/") {
            this.scrollToMyRef('contact');
        } else {
            this.props.history.push({
                pathname: '/',
                scroll_id: 'contact'
            })
        }
    }

    render() {
        var props = this.props;
        var toggleLogin = (localStorage.getItem('user_data')) ? true : false;
        let drawerlasses = 'side_drawer';
        if (props.show) {
            drawerlasses = 'side_drawer open';
        }
        return (
            <nav className={drawerlasses}>
                <Link to="/" style={{ display: 'flex', flexDirection: 'row', textDecoration: 'none', marginTop: '10px' }}>
                    <img className="onPage_side_drawer_Logo" src={require('../../images/logo.png')} onClick={props.click} />
                    <div className="onPage_side_drawer_Heading" onClick={props.click}>AUDiFYZ</div>
                </Link>
                <a href="tel:+18003403530">
                    <div className="sideDrawer_item">
                        Call Now <img className="homepageChat_img" style={{width:'16px', height:'16px'}} src={require('../../images/telephone-blue.png')} alt="telephone-white" /> 
                    </div>
                </a>

                
                <div className={window.location.pathname === "/" ? "sideDrawerBgSelected" : "sideDrawer_item"}
                    onClick={() => this.navigate("/")}>
                    Home</div>
                <div className={window.location.pathname === "/how-to-make-an-audiobook-create-your-own/" ? "sideDrawerBgSelected" : "sideDrawer_item"}
                    onClick={() => this.navigate("/how-to-make-an-audiobook-create-your-own/")}>How it works</div>
                <div className={"sideDrawer_item"}
                    onClick={() => this.pricingPage()}>Pricing</div>

                {(toggleLogin) ?
                    <div className={window.location.pathname === "/projects" ? "sideDrawerBgSelected" : "sideDrawer_item"}
                        onClick={() => this.navigate("/projects")}>Audiobooks</div>
                    :
                    <div className={window.location.pathname === "/projects" ? "sideDrawerBgSelected" : "sideDrawer_item"}
                        onClick={() => this.navigate("/projects")}>Projects</div>
                }
                 
                <div className={window.location.pathname === "hvr-underline-from-center" ? "sideDrawerBgSelected" : "sideDrawer_item"}
                    onClick={() => this.openSocialMedia("https://www.blog.audifyz.com")}>Blog</div>

                <div className={window.location.pathname === "/videoPage" ? "sideDrawerBgSelected" : "sideDrawer_item"}
                    onClick={() => this.navigate("/videoPage")}>Videos</div>

                <div className={window.location.pathname === "/schedule-for-demo" ? "sideDrawerBgSelected" : "sideDrawer_item"} 
                    onClick={() => this.navigate("/schedule-for-demo")}>Schedule For Demo</div>
                                
                <div className={window.location.pathname === "/audio-book-production" ? "sideDrawerBgSelected" : "sideDrawer_item"}
                    onClick={() => this.navigate("/audio-book-production")}>Help</div>
                <div className={"sideDrawer_item"}
                    onClick={() => this.onContactUsPress()}>Contact Us</div>

                {(toggleLogin) ?
                    <div className="sideDrawer_item" onClick={props.click}><div to="/" onClick={() => this.logOut()}>Logout</div>
                    </div>
                    :
                    <div className={window.location.pathname === "/login" ? "sideDrawerBgSelected" : "sideDrawer_item"}
                        onClick={props.click}><div onClick={() => { this.navigate('/login') }}>Login</div></div>
                }
            </nav>
        );
    }
};

export default (withRouter(SideDrawer));

