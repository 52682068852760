const USER_LOGIN = "USER_LOGIN";
const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

const initialState = {
    //data:{},
    isfetching: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_LOGIN: {
            return {
                ...state,
                isfetching: true,
            }
        }
        case USER_LOGIN_SUCCESS: {
            return {
                ...state,
                isfetching: false,
                data: action.data,
            }
        }
        case USER_LOGIN_FAIL: {
            return {
                ...state,
                isfetching: false,
                fail: true,
            }
        }
        default: return state
    }
}