import React from 'react';
import { navigateToUrl } from '../../utils/Navigating';
import { withRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';

var tawkTo = require('./chatSDK');
var Tawk_API;

class chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showChat: false
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user_data')) {
            this.setState((state, props) => {
                return {
                    isLoggedIn: true,
                    userData: JSON.parse(localStorage.getItem('user_data')),
                    showChat: true
                }
            })
            this.initalizeChat();
        }
    }

    initalizeChat() {
        tawkTo.init('5f056b7e223d045fcb7b84d3', (TawkAPI) => {
            Tawk_API = TawkAPI;
            TawkAPI.setAttributes({
                'name': this.state.userData.profile_name,
                'email': this.state.userData.email,
            }, function (error) {
                console.log("error_message", error)
            });
        })
    }

    redirectToLogin() {
        var urlObj = {
            pathname: '/login',
        }
        navigateToUrl(this.props, urlObj)
    }

    render() {
        return (
            <div>
                {
                    this.state.showChat === false && <div className="homepageChat_Main">
                        <img onClick={() => {
                            const event = {
                                event: 'chat_button_click',
                                button_label: 'chat'
                              };
                            TagManager.dataLayer({ dataLayer: event });
                            this.redirectToLogin()
                        }} className="homepageChat_img" src={require('../../images/chat.png')} alt="chat" />
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(chat);