import React from 'react';
import Loader from 'react-loader-spinner'
import { usePromiseTracker } from "react-promise-tracker";
import './Spinner.css'

export const Spinnersm = props => {
    const { promiseInProgress } = usePromiseTracker({ area: props.area });

    return (
        promiseInProgress && (
            <div className="spinnersm">
                <Loader type="Bars" color="#30217F" width={30} height={30} />
            </div>
        )
    );
};


export default Spinnersm;
