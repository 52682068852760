import { combineReducers } from 'redux';
import loginUserreducer from './modules/loginUser';
import createNewChapterreducer from './modules/createNewChapter';
import getPagesandChaptersreducer from './modules/viewPagesandChapters';
import setAuthReducer from './modules/setAuth';

const rootReducer = combineReducers({

    loginUserreducer,
    createNewChapterreducer,
    getPagesandChaptersreducer,
    setAuthReducer,
});

export default rootReducer;
