import React from 'react';
import './OnpageToolbar.css';
import { Link, withRouter } from 'react-router-dom';
import Login from '../Login/Login';
import { Modal } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import { navigateToUrl } from '../../utils/Navigating'
import ExitFeedback from '../Exitfeedback/exitfeedback';
import TagManager from 'react-gtm-module';



//connect to Store

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setAuth from '../../data/actions/setAuth'


function mapStateToProps(state) {
    return {
        SETAUTH: state.setAuthReducer
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setAuth
    }, dispatch);
}


class onPageToolbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginmodal: false,
            isLoggedIn: false,
            userData: null,
        };
    }

    componentDidMount() {
        if (localStorage.getItem('user_data') && JSON.parse(localStorage.getItem('user_data')).token) {
            this.props.setAuth(true, JSON.parse(localStorage.getItem('user_data')))
        }
        this.setState((state, props) => {
            return {
                isLoggedIn: props.SETAUTH.isAuth,
                userData: props.SETAUTH.user
            }
        })
    }

    
    openSocialMedia(url=''){
        window.open(url,"_blank")
    }
    
    static getDerivedStateFromProps(nextprops, prevstate) {
        if (nextprops.SETAUTH.isAuth) {
            return {
                isLoggedIn: nextprops.SETAUTH.isAuth,
                userData: nextprops.SETAUTH.user
            }
        }
        return null;
    }


    handleLoginModal() {
        this.setState((state, props) => {
            return { loginmodal: !state.loginmodal };
        });
    }

    renderLoginModal() {
        return (
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.loginmodal} onHide={() => this.handleLoginModal()}>
                <Login />
            </Modal>
        );
    }

    logOut() {
        localStorage.removeItem('user_data');
        localStorage.removeItem('user_Provider_data');
        this.props.setAuth(false)
        window.location.reload();
        this.navigation("/");
    }

    renderUserItems() {
        var date = (this.state.selectedDate == null) ? new Date() : new Date(this.state.selectedDate);
        var mindate = new Date(new Date().setDate(new Date().getDate() - 30));
        return (
            <Popover style={{ width: '100%' }} id="popover-basic">
                <Popover.Title as="h3" style={{ marginTop: '0px' }}>
                    <div className="toolbar_userlist_title">
                        <img className="onPageToolbar_popover_avatar_image" src={require('../../images/userpopUp.png')} alt={'userpopUp'} />
                        <div>{this.state.userData && this.state.userData.profile_name}</div>
                    </div>
                </Popover.Title>
                <Popover.Content>
                    {/*<div className="toolbar_popover_listitem">
                        <img style={{ width: '8%', marginRight: '5%' }} src={require('../../images/editpro.png')} alt={'card avatar'} className="toolbar_popover_avatar_image" />
                        <div style={{ cursor: 'pointer', color: 'blue' }} onClick={() => this.navigation("/")}>Edit Profile</div>
                    </div>*/}
                    {/* <div className="toolbar_popover_listitem_separator"></div> */}
                    <div onClick={() => this.logOut()} className="toolbar_popover_listitem">
                        <img style={{ width: '10%', marginRight: '5%' }} src={require('../../images/logout.png')} alt={'logout'} className="toolbar_popover_avatar_image" />
                        <div style={{ cursor: 'pointer', color: 'blue' }} onClick={() => this.logOut()}>Logout</div>
                    </div>
                </Popover.Content>
            </Popover>
        );
    }

    navigation(url) {
        var urlObj = {
            pathname: url,
        };
        navigateToUrl(this.props, urlObj);
    }

    navigation_Readmore(url) {
        var urlObj = {
            pathname: url,
            sroll_id: 'home'
        }
        navigateToUrl(this.props, urlObj);
    }

    render() {
        var props = this.props;
        return (
            <div className="onPageToolbar_Main navbarposition">
                <div className="onPageToolbar_Heading_Main">
                    <div className="OnapageToolbar_toggle_button" style={{ marginTop: '5px', marginRight: '15px', marginLeft: '10px' }}>
                        <DrawerToggleButton click={props.drawerClickHandler} />
                    </div>
                    <div onClick={() => this.navigation("/")} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                        <img className="onPageToolbar_Logo" src={'https://d278admdl7nfkq.cloudfront.net/Images/logo_audifyz.png'} onClick={() => this.props.onToolbarItemPress('top')} alt='logo_audifyz' />
                        <div className="onPageToolbar_Heading" onClick={() => this.props.onToolbarItemPress('top')}>AUDiFYZ</div>
                    </div>
                </div>
                <div className="onPageToolbar_Components">
                    {/* <li>
                        <a href="tel:+18003403530"><div style={{ textDecoration: 'none', color: '#ffffff', textShadow: '2px 2px 4px #1000ff' }} className="hvr-underline-from-center-white"> Call Now <img className="homepageChat_img" style={{width:'16px', height:'16px'}} src={require('../../images/telephone-white.png')} alt="telephone-white" /> 1-800-340-3530</div></a>
                    </li> */}
                    {
                        this.state.isLoggedIn && this.state.userData && this.state.userData.user_role === 3 ?
                            <div></div>
                            :
                            <li style={{color: "#F8F0E3" , fontWeight: "bold"}}>
                                <div className={"hvr-underline-from-center-white"} onClick={() => {
                                    // props.onScheduleEvent();
                                    const tagManagerArgs = {
                                        gtmId: 'GTM-MCXLRXN',
                                        dataLayerName: 'schedule for demo'
                                    }
                                    TagManager.initialize(tagManagerArgs);
                                    this.navigation_Readmore('/schedule-for-demo')
                                }}>Schedule For Demo</div>
                                
                            </li>
                    }
                    {
                        this.state.isLoggedIn && this.state.userData && this.state.userData.user_role === 3 ?
                            <div></div>
                            :
                            <li>
                                <div onClick={() => {
                                    const event = {
                                        event: 'how_it_works_button_click',
                                        button_label: 'How it works'
                                      };
                                    TagManager.dataLayer({ dataLayer: event });
                                    this.navigation_Readmore('/how-to-make-an-audiobook-create-your-own')
                                }} style={{ textDecoration: 'none', color: '#ffffff' }} className="hvr-underline-from-center-white">How it works</div>
                            </li>
                    }
                    {
                        this.state.isLoggedIn && this.state.userData && this.state.userData.user_role === 3 ?
                            <div></div>
                            :
                            <li>
                                <div style={{ color: '#ffffff' }} className="hvr-underline-from-center-white" onClick={() => {
                                    const event = {
                                        event: 'pricing_button_click',
                                        button_label: 'Pricing'
                                      };
                                    TagManager.dataLayer({ dataLayer: event });
                                    this.props.onToolbarItemPress('pricing')
                                }}>
                                    Pricing
                                </div>
                            </li>
                    }
                    {this.state.isLoggedIn &&
                        <li>
                            <div style={{ color: '#ffffff' }} onClick={() => {
                                const event = {
                                    event: 'audiobooks_button_click',
                                    button_label: 'Audiobooks'
                                  };
                                TagManager.dataLayer({ dataLayer: event });
                                this.navigation("/projects")
                            }} className="hvr-underline-from-center-white">Audiobooks</div>
                        </li>
                    }
                    {!this.state.isLoggedIn &&
                        <li>
                            <div style={{ color: '#ffffff' }} onClick={() => {
                                const event = {
                                    event: 'projects_button_click',
                                    button_label: 'Projects'
                                  };
                                TagManager.dataLayer({ dataLayer: event });
                                this.navigation("/projects")
                            }} className="hvr-underline-from-center-white">
                                Projects</div>
                        </li>
                    }  
                    <li>
                        <div style={{ color: '#ffffff' }} onClick={() => {
                            const event = {
                                    event: 'blog_button_click',
                                    button_label: 'Blog'
                                  };
                            TagManager.dataLayer({ dataLayer: event });
                            this.openSocialMedia("https://www.blog.audifyz.com")
                        }} className="hvr-underline-from-center-white">Blog</div>
                    </li>     
                    <li>
                        <div style={{ color: '#ffffff' }} onClick={() => {
                                const event = {
                                    event: 'videos_button_click',
                                    button_label: 'Videos'
                                  };
                                TagManager.dataLayer({ dataLayer: event });
                            this.navigation("/videoPage")
                        }} className="hvr-underline-from-center-white">Videos</div>
                    </li>     
                    <li>
                        <div style={{ color: '#ffffff' }} onClick={() => {
                            
                                const event = {
                                    event: 'help_button_click',
                                    button_label: 'Help'
                                  };
                                TagManager.dataLayer({ dataLayer: event });
                            this.navigation("/audio-book-production")
                        }} className="hvr-underline-from-center-white">Help</div>
                    </li>
                    <li>
                        <div style={{ color: '#ffffff' }} className="hvr-underline-from-center-white" onClick={() => {
                           
                                const event = {
                                    event: 'contact_us_button_click',
                                    button_label: 'Contact us'
                                  };
                                TagManager.dataLayer({ dataLayer: event });
                            this.props.onToolbarItemPress('contact')
                        }}>Contact us</div>
                    </li>
                    {this.state.isLoggedIn &&
                        <OverlayTrigger trigger="click" placement="left" overlay={this.renderUserItems()} rootClose>
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }}>
                                    <img src={require('../../images/user.png')} alt={'user'} className="onPageToolbar_avatar" />
                                    <div style={{ fontSize: '0.9vw', color: '#ffffff' }}>&#x25bc;</div>
                                </div>
                            </div>
                        </OverlayTrigger>
                    }
                    {!this.state.isLoggedIn &&
                        <li>
                            <div style={{ color: '#ffffff', marginRight: '15px' }} onClick={() => {
                                
                                const event = {
                                    event: 'login_button_click',
                                    button_label: 'Login'
                                  };
                                TagManager.dataLayer({ dataLayer: event });
                                this.navigation("/login")
                            }} className="hvr-underline-from-center-white">Login</div>
                        </li>
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(onPageToolbar));