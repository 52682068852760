import React from 'react';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import './Toolbar.css'
import { Link, withRouter } from 'react-router-dom';
import Login from '../Login/Login';
import { Modal } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { navigateToUrl } from '../../utils/Navigating'
import Chat from '../ChatLibrary/chat.js';
import TagManager from 'react-gtm-module';

//connect to Store

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setAuth from '../../data/actions/setAuth'


function mapStateToProps(state) {
    return {
        SETAUTH: state.setAuthReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setAuth
    }, dispatch);
}


class Toolbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginmodal: false,
            isLoggedIn: false,
            userData: null,
        };
    }

    componentDidMount() {
        if (localStorage.getItem('user_data') && JSON.parse(localStorage.getItem('user_data')).token) {
            this.props.setAuth(true, JSON.parse(localStorage.getItem('user_data')))
        }
        this.setState((state, props) => {
            return {
                isLoggedIn: props.SETAUTH.isAuth,
                userData: props.SETAUTH.user
            }
        })
    }

    openSocialMedia(url=''){
        window.open(url,"_blank")
    }
    

    static getDerivedStateFromProps(nextprops, prevstate) {
        if (nextprops.SETAUTH.isAuth) {
            return {
                isLoggedIn: nextprops.SETAUTH.isAuth,
                userData: nextprops.SETAUTH.user
            }
        }
        return null;
    }


    handleLoginModal() {
        this.setState((state, props) => {
            return { loginmodal: !state.loginmodal };
        });
    }

    renderLoginModal() {
        return (
            <Modal
                size="lg"
                animation={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.loginmodal} onHide={() => this.handleLoginModal()}>
                <Login />
            </Modal>
        );
    }

    logOut() {
        localStorage.removeItem('user_data');
        this.props.setAuth(false)
        window.location.reload();
    }

    navigate(url) {
        var urlObj = {
            pathname: url,
        };
        navigateToUrl(this.props, urlObj)
    }

    renderUserItems() {
        var date = (this.state.selectedDate == null) ? new Date() : new Date(this.state.selectedDate);
        var mindate = new Date(new Date().setDate(new Date().getDate() - 30));
        return (
            <Popover style={{ width: '100%' }} id="popover-basic">
                <Popover.Title as="h3" style={{ marginTop: '0px' }}>
                    <div className="toolbar_userlist_title">
                        <img src={require('../../images/userpopUp.png')} alt={'userpopUp'} className="toolbar_popover_avatar_image" />
                        <div>{this.state.userData && this.state.userData.profile_name}</div>
                        {/* <div style={{fontSize:'65%',paddingTop:'5px',color:'greypr'}}>{this.state.userData && !this.state.userData.is_verified && ' ,unverified'}</div> */}
                    </div>
                </Popover.Title>
                <Popover.Content>
                    {/* <div className="toolbar_popover_listitem">
                        <img style={{ width: '8%', marginRight: '5%' }} src={require('../../images/editpro.png')} alt={'card avatar'} className="toolbar_popover_avatar_image" />
                        <div>Edit Profile</div>
                    </div> */}
                    {/* <div className="toolbar_popover_listitem_separator"></div> */}
                    <div onClick={() => this.logOut()} className="toolbar_popover_listitem">
                        <img style={{ width: '10%', marginRight: '5%' }} src={require('../../images/logout.png')} alt={'logout'} className="toolbar_popover_avatar_image" />
                        <Link to="/" onClick={() => this.logOut()}>Logout</Link>
                    </div>
                </Popover.Content>
            </Popover>
        );
    }

    pricingPage() {
        this.props.onListpress(2);
        this.props.history.push({
            pathname: '/',
            scroll_id: 'pricing'
        })
    }

    onContactUsPress() {
        this.props.onListpress(3);
        this.props.history.push({
            pathname: '/',
            scroll_id: 'contact'
        })
    }

    render() {
        var props = this.props;
        return (
            <header className={window.location.pathname === "/" ? "toolbarNone" : "toolbar navbarposition"}>
                <nav className="toolbar_navigation">
                    <div className="toolbar_toggle_button">
                        <DrawerToggleButton click={props.drawerClickHandler} />
                    </div>
                    <div className="toolbar_logo">
                        <Link to="/" style={{ display: 'flex', flexDirection: 'row', textDecoration: 'none' }}>
                            <img className="audifyzLogo" src={require('../../images/logo_audifyz_copy.png')} />
                            <div className="HeadingAppName">AUDiFYZ</div>
                        </Link>
                    </div>
                    <div className="spacer"></div>
                    <div className="toolbar_navigation_items">
                        {/* <li>
                            <a href="tel:+18003403530"><div style={{textShadow: '2px 2px 4px rgb(192 198 215)' }} className="hvr-underline-from-center"> Call Now <img className="homepageChat_img" style={{ width: '16px', height: '16px' }} src={require('../../images/telephone-blue.png')} alt="telephone-blue" /> 1-800-340-3530</div></a>
                        </li> */}
                        {
                            this.state.isLoggedIn && this.state.userData && this.state.userData.user_role === 3 || this.props.isScheduleTabActive ?
                                <div></div>
                                :
                                <li className='custom-schedule-button'>
                                    <div className={"hvr-underline-from-center"} onClick={() => {
                                         props.onScheduleEvent();
                                        }}>Schedule For Demo</div>
                                </li>
                        }
                        {
                            this.state.isLoggedIn && this.state.userData && this.state.userData.user_role === 3 ?
                                <div></div>
                                :
                                <li>
                                    <div className={window.location.pathname === "/how-to-make-an-audiobook-create-your-own" ? "toolbarOnselectedpage" : "hvr-underline-from-center"} onClick={() => {
                                       const event = {
                                            event: 'button_click',
                                            button_label: 'how-to-make-an-audiobook-create-your-own'
                                          };
                                        TagManager.dataLayer({ dataLayer: event });
                                       this.navigate("/how-to-make-an-audiobook-create-your-own")}}>How it works</div>
                                </li>
                        }
                        {
                            this.state.isLoggedIn && this.state.userData && this.state.userData.user_role === 3 ?
                                <div></div>
                                :
                                <li onClick={() => this.pricingPage()}><div className="hvr-underline-from-center">Pricing</div></li>

                        }
                        {this.state.isLoggedIn &&
                            <li><div className={window.location.pathname === "/projects" ? "toolbarOnselectedpage" : "hvr-underline-from-center"} onClick={() => this.navigate("/projects")}>Audiobooks</div></li>
                        }
                        {!this.state.isLoggedIn &&
                            <li><div className="hvr-underline-from-center" onClick={() => this.navigate("/projects")}>Projects
                            </div></li>
                        }
 
                        <li onClick={() => this.openSocialMedia("https://www.blog.audifyz.com")}><div className="hvr-underline-from-center">Blog</div></li>
                        <li><div className={window.location.pathname === "/videoPage" ? "toolbarOnselectedpage" : "hvr-underline-from-center"} onClick={() => this.navigate("/videoPage")}>Videos</div></li>
                       
                        <li><div className={window.location.pathname === "/audio-book-production" ? "toolbarOnselectedpage" : "hvr-underline-from-center"} onClick={() => this.navigate("/audio-book-production")}>Help</div></li>

                        <li onClick={() => this.onContactUsPress()}><div className="hvr-underline-from-center">Contact us</div></li>

                        {this.state.isLoggedIn &&
                            <OverlayTrigger trigger="click" placement="left" overlay={this.renderUserItems()} rootClose>
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                        <img src={require('../../images/userToolbar.png')} alt={'userToolbar'} className="toolbar_avatar_image" />
                                        <div style={{ fontSize: '0.9vw', color: '#59CBB7' }}>&#x25bc;</div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        }
                        {!this.state.isLoggedIn &&
                            <li><div className={window.location.pathname === "/login" ? "toolbarOnselectedpage" : "hvr-underline-from-center"} onClick={() => this.navigate("/login")}>Login</div></li>
                        }
                    </div>
                </nav>
                {this.renderLoginModal()}
                <Chat />
            </header>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Toolbar));
