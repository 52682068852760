import React from 'react';
import Loader from 'react-loader-spinner'
import { usePromiseTracker } from "react-promise-tracker";
import './Spinner.css'


export const Spinner = (props) => {
    const { promiseInProgress } = usePromiseTracker({ area: props.area });

    return (
        <div>
            {
                promiseInProgress && (
                    <div className="modal_spinner_new">
                        <Loader type="Bars" color="#30217F" height={100} width={100} />
                    </div>
                )
            }
        </div>
    );
};


export default Spinner;
