import React from 'react';
import Loader from 'react-loader-spinner'
import './Spinner.css'

class Spinner extends React.Component{

    render(){
        return(
            <div>
                <div className="spinner">
                    <Loader type="Bars" color="#30217F" height={100} width={300} />
                </div>
            </div>
        );
    }
}
  

export default Spinner;
